import React from "react";
import fbImg from "../../assests/images/facebook.png";
import instaImg from "../../assests/images/instagram.png";
import linkedinImg from "../../assests/images/linkedin.png";
import "./Footer.css";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import quoraImg from "../../assests/images/quora.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer>
      <div className="footer-section">
        <div className="footer-first-container">
          <div className="first-container-item">
            <h6 className="footer-subheading">SAS Experts</h6>
            <div className="footer-txt-container">
              <p>
                <Link className="footer-email" to="/aboutus">
                  Home
                </Link>
              </p>
              <p>
                <Link className="footer-email" to="/aboutus">
                  About Us
                </Link>
              </p>
              <p>
                <Link className="footer-email" to="/ourteam">
                  Our Team
                </Link>
              </p>
              <p>
                <Link className="footer-email" to="/contactus">
                  Contact Us
                </Link>
              </p>
              <p>
                <Link className="footer-email" to="/services">
                  Services
                </Link>
              </p>
            </div>
          </div>
          <div className="first-container-item">
            <h6 className="footer-subheading">Our Services</h6>
            <div className="footer-txt-container">
              <p>
                <Link className="footer-email" to="/services">
                  PR Visa
                </Link>
              </p>
              <p>
                <Link className="footer-email" to="/services">
                  Study Visa
                </Link>
              </p>
              <p>
                <Link className="footer-email" to="/services">
                  Visit Visa
                </Link>
              </p>
              <p>
                <Link className="footer-email" to="/services">
                  Work Visa
                </Link>
              </p>
              <p>
                <Link className="footer-email" to="/services">
                  Immigration
                </Link>
              </p>
            </div>
          </div>
          {/* <div className="first-container-item">
            <h6 className="footer-subheading">News & Blog</h6>
            <div className="footer-txt-container">
              <p>First Blog</p>
              <p>First Blog</p>
              <p>First Blog</p>
            </div>
          </div> */}
          <div className="first-container-item">
            <h6 className="footer-subheading">Newsletter Signup</h6>
            <div className="footer-txt-container">
              <p>
                For more Information and get latest updates and offers subscribe
                us
              </p>
            </div>
            <div className="social-icons-container">
              <a
                href="https://www.facebook.com/profile.php?id=100078179028620"
                target="_blank"
              >
                <img src={fbImg} alt="social-icon" className="social-img" />
              </a>
              <a href="https://www.instagram.com/sasexperts/" target="_blank">
                <img src={instaImg} alt="social-icon" className="social-img" />
              </a>
              <a
                href="https://www.linkedin.com/in/sas-experts-immigration-aa89a8246/"
                target="_blank"
              >
                <img
                  src={linkedinImg}
                  alt="social-icon"
                  className="social-img"
                />
              </a>
              <a
                href="https://www.quora.com/profile/SAS-EXPERTS"
                target="_blank"
              >
                <img src={quoraImg} alt="social-icon" className="social-img" />
              </a>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="footer-second-container">
          <div className="second-container-item">
            <PhoneIcon className="icon" />
            <div>
              <h6 className="second-container-subheading">Call Us</h6>
              <a href="tel:+917997001119" className="footer-email">
                +91 799 7001 119
              </a>
              <h6 className="second-container-subheading">Opening Hours</h6>
              <p>Monday-Saturday : 10am to 7pm</p>
            </div>
          </div>
          <div className="second-container-item">
            <EmailIcon className="icon" />
            <div>
              <h6 className="second-container-subheading">Email Us</h6>
              <a className="footer-email" href="mailto:sasexperts.in@gmail.com">
                info@sasexperts.in
              </a>
            </div>
          </div>
          <div className="second-container-item">
            <LocationOnIcon className="icon" />
            <div>
              <h6 className="second-container-subheading">Main Branch</h6>
              <p>
                203 & 204, 2nd floor, Babhukhan Mall, Somajiguda Circle,
                Hyderabad - 500082.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-txt">
        © 2023 Copyright SAS Experts All rights reserved
      </div>
      <div className="whats-app-icon">
        <a
          className="whatsapp-anchor"
          target="_blank"
          rel="noreferrer"
          href="https://wa.me/917997001119"
        >
          <WhatsAppIcon className="whatsapp-icon" />
        </a>
      </div>
      <div
        onClick={scrollToTop}
        className="scroll-icon-container scroll-to-top-button"
      >
        <a className="whatsapp-anchor" href="#">
          <ArrowBackIosNewIcon className="scroll-icon" />
        </a>
      </div>
    </footer>
  );
};
export default Footer;
