import React from "react";
import "./Testimonial.css";
import studentImg from "../../assests/images/student.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testi1Img from "../../assests/images/testi1.jpeg";
import testi2Img from "../../assests/images/testi2.jpeg";
import testi3Img from "../../assests/images/testi3.jpg";
import testi4Img from "../../assests/images/testi4.jpeg";
import StarIcon from "@mui/icons-material/Star";

export const Testimonial = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 5,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    initialSlide: 0,
    centerPadding: 100,
    cssEase: "linear",
    lazyLoad: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <section className="testimonials-section">
      <div className="title-container">
        <h1 className="heading">About Our Customer Say</h1>
      </div>
      <div>
        <Slider {...settings}>
          <div className="testimonial-container">
            <div className="testi-text-container">
              <div>
                <div className="quote">
                  <svg
                    width="33"
                    height="22"
                    viewBox="0 0 33 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.0267741 14.2441C0.0232465 14.3384 0.0214669 14.4331 0.0214669 14.5283C0.0214665 18.6548 3.36666 22 7.49316 22C11.6197 22 14.9648 18.6548 14.9648 14.5283C14.9648 10.4018 11.6197 7.05662 7.49316 7.05662C6.73028 7.05662 5.99411 7.17095 5.30084 7.38341C6.8185 4.06918 11.3775 1.11242 13.5805 1.32304e-05C2.06855 2.26919 -0.255979 10.4465 0.0207498 14.2516L0.0267741 14.2441Z"
                      fill="#226CF5"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.0492 14.2439C18.0457 14.3382 18.0439 14.4331 18.0439 14.5283C18.0439 18.6548 21.3891 22 25.5156 22C29.6421 22 32.9873 18.6548 32.9873 14.5283C32.9873 10.4018 29.6421 7.05662 25.5156 7.05662C24.7527 7.05662 24.0164 7.17097 23.3231 7.38347C24.8408 4.06924 29.3998 1.11247 31.6028 6.47288e-05C20.0908 2.26924 17.7663 10.4466 18.043 14.2516L18.0492 14.2439Z"
                      fill="#226CF5"
                    ></path>
                  </svg>
                </div>
                <div className="testi-student-title">Abhishek Y</div>
                <div className="testi-text">Study Visa</div>
              </div>
              <div className="testi-text">
                &quot;Obtaining my study visa seemed like a daunting task until
                I reached out to <b>SAS EXPERTS</b>. Their knowledgeable
                consultants provided me with step-by-step guidance and valuable
                insights throughout the entire process. They made sure that all
                my documents were properly prepared, and they kept me informed
                about the progress of my application. Thanks to their
                professionalism and support, I am now studying at{" "}
                <b>University of ESSEX Colchester Campus</b>, and I couldn&#39;t
                be happier. I am grateful for <b>SAS EXPERTS</b> for making my
                study abroad dreams come true!&quot;
              </div>
              <div className="stars">
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
              </div>
            </div>
            <div>
              <img className="testiImg" src={testi1Img} alt="student-image" />
            </div>
          </div>
          <div className="testimonial-container">
            <div className="testi-text-container">
              <div>
                <div className="quote">
                  <svg
                    width="33"
                    height="22"
                    viewBox="0 0 33 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.0267741 14.2441C0.0232465 14.3384 0.0214669 14.4331 0.0214669 14.5283C0.0214665 18.6548 3.36666 22 7.49316 22C11.6197 22 14.9648 18.6548 14.9648 14.5283C14.9648 10.4018 11.6197 7.05662 7.49316 7.05662C6.73028 7.05662 5.99411 7.17095 5.30084 7.38341C6.8185 4.06918 11.3775 1.11242 13.5805 1.32304e-05C2.06855 2.26919 -0.255979 10.4465 0.0207498 14.2516L0.0267741 14.2441Z"
                      fill="#226CF5"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.0492 14.2439C18.0457 14.3382 18.0439 14.4331 18.0439 14.5283C18.0439 18.6548 21.3891 22 25.5156 22C29.6421 22 32.9873 18.6548 32.9873 14.5283C32.9873 10.4018 29.6421 7.05662 25.5156 7.05662C24.7527 7.05662 24.0164 7.17097 23.3231 7.38347C24.8408 4.06924 29.3998 1.11247 31.6028 6.47288e-05C20.0908 2.26924 17.7663 10.4466 18.043 14.2516L18.0492 14.2439Z"
                      fill="#226CF5"
                    ></path>
                  </svg>
                </div>
                <div className="testi-student-title">Chikirshaa K</div>
                <div className="testi-text">PR Visa</div>
              </div>
              <div className="testi-text">
                &quot;I am incredibly grateful for the support and guidance I
                received from <b>SAS EXPERTS</b> in obtaining my study visa.
                Their team went above and beyond to ensure that all my documents
                were in order and that my application was submitted on time.
                Thanks to their expertise and attention to detail, I am now
                pursuing my dream education in <b>Australia</b>. I highly
                recommend <b>SAS EXPERTS</b> to anyone seeking assistance with
                their study visa application.&quot;
              </div>
              <div className="stars">
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
              </div>
            </div>
            <div>
              <img className="testiImg" src={testi2Img} alt="student-image" />
            </div>
          </div>
          <div className="testimonial-container">
            <div className="testi-text-container">
              <div>
                <div className="quote">
                  <svg
                    width="33"
                    height="22"
                    viewBox="0 0 33 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.0267741 14.2441C0.0232465 14.3384 0.0214669 14.4331 0.0214669 14.5283C0.0214665 18.6548 3.36666 22 7.49316 22C11.6197 22 14.9648 18.6548 14.9648 14.5283C14.9648 10.4018 11.6197 7.05662 7.49316 7.05662C6.73028 7.05662 5.99411 7.17095 5.30084 7.38341C6.8185 4.06918 11.3775 1.11242 13.5805 1.32304e-05C2.06855 2.26919 -0.255979 10.4465 0.0207498 14.2516L0.0267741 14.2441Z"
                      fill="#226CF5"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.0492 14.2439C18.0457 14.3382 18.0439 14.4331 18.0439 14.5283C18.0439 18.6548 21.3891 22 25.5156 22C29.6421 22 32.9873 18.6548 32.9873 14.5283C32.9873 10.4018 29.6421 7.05662 25.5156 7.05662C24.7527 7.05662 24.0164 7.17097 23.3231 7.38347C24.8408 4.06924 29.3998 1.11247 31.6028 6.47288e-05C20.0908 2.26924 17.7663 10.4466 18.043 14.2516L18.0492 14.2439Z"
                      fill="#226CF5"
                    ></path>
                  </svg>
                </div>
                <div className="testi-student-title"> Atif Ali Khan</div>
                <div className="testi-text">PR Visa</div>
              </div>
              <div className="testi-text">
                &quot;I want to express my deepest gratitude to
                <b> SAS EXPERTS</b> for their exceptional assistance in
                obtaining my study visa. Their team&#39;s expertise and
                understanding of the visa application process made everything so
                much smoother for me. They guided me through every step, helped
                me gather the necessary documents, and provided valuable advice
                along the way. Thanks to their dedicated efforts, I am now
                pursuing my desired course at{" "}
                <b>University of Technology Sydney</b>, and I couldn&#39;t be
                more thrilled. I highly recommend
                <b>SAS EXPERTS</b> to anyone seeking professional assistance
                with their study visa.
              </div>
              <div className="stars">
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
              </div>
            </div>
            <div>
              <img className="testiImg" src={testi3Img} alt="student-image" />
            </div>
          </div>
          <div className="testimonial-container">
            <div className="testi-text-container">
              <div>
                <div className="quote">
                  <svg
                    width="33"
                    height="22"
                    viewBox="0 0 33 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.0267741 14.2441C0.0232465 14.3384 0.0214669 14.4331 0.0214669 14.5283C0.0214665 18.6548 3.36666 22 7.49316 22C11.6197 22 14.9648 18.6548 14.9648 14.5283C14.9648 10.4018 11.6197 7.05662 7.49316 7.05662C6.73028 7.05662 5.99411 7.17095 5.30084 7.38341C6.8185 4.06918 11.3775 1.11242 13.5805 1.32304e-05C2.06855 2.26919 -0.255979 10.4465 0.0207498 14.2516L0.0267741 14.2441Z"
                      fill="#226CF5"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.0492 14.2439C18.0457 14.3382 18.0439 14.4331 18.0439 14.5283C18.0439 18.6548 21.3891 22 25.5156 22C29.6421 22 32.9873 18.6548 32.9873 14.5283C32.9873 10.4018 29.6421 7.05662 25.5156 7.05662C24.7527 7.05662 24.0164 7.17097 23.3231 7.38347C24.8408 4.06924 29.3998 1.11247 31.6028 6.47288e-05C20.0908 2.26924 17.7663 10.4466 18.043 14.2516L18.0492 14.2439Z"
                      fill="#226CF5"
                    ></path>
                  </svg>
                </div>
                <div className="testi-student-title"> Dinesh D</div>
                <div className="testi-text">Canada PR</div>
              </div>
              <div className="testi-text">
                &quot;I am overjoyed to share my experience of obtaining my
                <b> Canadian Permanent Residency (PR) visa</b>. With the expert
                guidance of <b>SAS EXPERTS</b>, the process was seamless and
                efficient. They navigated the complex requirements with
                precision, ensuring that all my documents were in order and my
                application was submitted on time. Today, I proudly call Canada
                my home, and I am grateful to <b>Mr.VINAY</b> for his unwavering
                support in making my PR dreams come true.&quot;
              </div>
              <div className="stars">
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
                <StarIcon className="staricon" />
              </div>
            </div>
            <div>
              <img className="testi3img" src={testi4Img} alt="student-image" />
            </div>
          </div>
        </Slider>
        {/* <Slider className="student-speak-section" {...settings}>
          <div className="item">
            <div className="student-card">
              <div className="student-review">
                <div className="quote">
                  <svg
                    width="33"
                    height="22"
                    viewBox="0 0 33 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.0267741 14.2441C0.0232465 14.3384 0.0214669 14.4331 0.0214669 14.5283C0.0214665 18.6548 3.36666 22 7.49316 22C11.6197 22 14.9648 18.6548 14.9648 14.5283C14.9648 10.4018 11.6197 7.05662 7.49316 7.05662C6.73028 7.05662 5.99411 7.17095 5.30084 7.38341C6.8185 4.06918 11.3775 1.11242 13.5805 1.32304e-05C2.06855 2.26919 -0.255979 10.4465 0.0207498 14.2516L0.0267741 14.2441Z"
                      fill="#226CF5"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.0492 14.2439C18.0457 14.3382 18.0439 14.4331 18.0439 14.5283C18.0439 18.6548 21.3891 22 25.5156 22C29.6421 22 32.9873 18.6548 32.9873 14.5283C32.9873 10.4018 29.6421 7.05662 25.5156 7.05662C24.7527 7.05662 24.0164 7.17097 23.3231 7.38347C24.8408 4.06924 29.3998 1.11247 31.6028 6.47288e-05C20.0908 2.26924 17.7663 10.4466 18.043 14.2516L18.0492 14.2439Z"
                      fill="#226CF5"
                    ></path>
                  </svg>
                </div>
                <div className="description">
                  <p>
                    I got complete support for shortlisting universities, majors
                    and writing SOPs. I would recommend every student applying
                    for the US to approach SAS.
                  </p>
                </div>
              </div>
              <div className="student-bref">
                <div className="student-img-container">
                  <img
                    src={studentImg}
                    className="student-photo"
                    alt="student_photo"
                  />
                </div>
                <div>
                  <p className="bold">Mithali Raj</p>
                  {/* <p>Nellore</p> */}
        {/* </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="student-card">
              <div className="student-review">
                <div className="quote">
                  <svg
                    width="33"
                    height="22"
                    viewBox="0 0 33 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.0267741 14.2441C0.0232465 14.3384 0.0214669 14.4331 0.0214669 14.5283C0.0214665 18.6548 3.36666 22 7.49316 22C11.6197 22 14.9648 18.6548 14.9648 14.5283C14.9648 10.4018 11.6197 7.05662 7.49316 7.05662C6.73028 7.05662 5.99411 7.17095 5.30084 7.38341C6.8185 4.06918 11.3775 1.11242 13.5805 1.32304e-05C2.06855 2.26919 -0.255979 10.4465 0.0207498 14.2516L0.0267741 14.2441Z"
                      fill="#226CF5"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.0492 14.2439C18.0457 14.3382 18.0439 14.4331 18.0439 14.5283C18.0439 18.6548 21.3891 22 25.5156 22C29.6421 22 32.9873 18.6548 32.9873 14.5283C32.9873 10.4018 29.6421 7.05662 25.5156 7.05662C24.7527 7.05662 24.0164 7.17097 23.3231 7.38347C24.8408 4.06924 29.3998 1.11247 31.6028 6.47288e-05C20.0908 2.26924 17.7663 10.4466 18.043 14.2516L18.0492 14.2439Z"
                      fill="#226CF5"
                    ></path>
                  </svg>
                </div>
                <div className="description">
                  <p>
                    I got complete support for shortlisting universities, majors
                    and writing SOPs. I would recommend every student applying
                    for the US to approach SAS.
                  </p>
                </div>
              </div>
              <div className="student-bref">
                <div className="student-img-container">
                  <img
                    src={studentImg}
                    className="student-photo"
                    alt="student_photo"
                  />
                </div>
                <div>
                  <p className="bold">Balredy Mahendra</p>
                  {/* <p>Nellore</p> */}
        {/* </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="student-card">
              <div className="student-review">
                <div className="quote">
                  <svg
                    width="33"
                    height="22"
                    viewBox="0 0 33 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.0267741 14.2441C0.0232465 14.3384 0.0214669 14.4331 0.0214669 14.5283C0.0214665 18.6548 3.36666 22 7.49316 22C11.6197 22 14.9648 18.6548 14.9648 14.5283C14.9648 10.4018 11.6197 7.05662 7.49316 7.05662C6.73028 7.05662 5.99411 7.17095 5.30084 7.38341C6.8185 4.06918 11.3775 1.11242 13.5805 1.32304e-05C2.06855 2.26919 -0.255979 10.4465 0.0207498 14.2516L0.0267741 14.2441Z"
                      fill="#226CF5"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.0492 14.2439C18.0457 14.3382 18.0439 14.4331 18.0439 14.5283C18.0439 18.6548 21.3891 22 25.5156 22C29.6421 22 32.9873 18.6548 32.9873 14.5283C32.9873 10.4018 29.6421 7.05662 25.5156 7.05662C24.7527 7.05662 24.0164 7.17097 23.3231 7.38347C24.8408 4.06924 29.3998 1.11247 31.6028 6.47288e-05C20.0908 2.26924 17.7663 10.4466 18.043 14.2516L18.0492 14.2439Z"
                      fill="#226CF5"
                    ></path>
                  </svg>
                </div>
                <div className="description">
                  <p>
                    I got complete support for shortlisting universities, majors
                    and writing SOPs. I would recommend every student applying
                    for the US to approach SAS.
                  </p>
                </div>
              </div>
              <div className="student-bref">
                <div className="student-img-container">
                  <img
                    src={studentImg}
                    className="student-photo"
                    alt="student_photo"
                  />
                </div>
                <div>
                  <p className="bold">Sunil G</p>
                  {/* <p>Nellore</p> */}
        {/* </div>
              </div>
            </div>
          </div>
        
        </Slider>  */}
      </div>
    </section>
  );
};
