import React, { useEffect } from "react";
import "./Contact.css";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import RoomIcon from "@mui/icons-material/Room";

export const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const formValidation = yup.object({
    Name: yup
      .string()
      .trim()
      .required("Full Name is required")
      .matches(/^[a-zA-Z ]*$/, "Only Alphabets are allowed"),
    Email: yup
      .string()
      .email("Enter Valid Email Address")
      .required("Email is required"),
    City: yup
      .string()
      .trim()
      .required("Full Name is required")
      .matches(/^[a-zA-Z ]*$/, "Only Alphabets are allowed"),
    PhoneNumber: yup
      .string()
      .required("Phone Number is required")
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Enter Valid Phone Number"
      ),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formValidation),
    mode: "onSubmit",
    defaultValues: {
      Name: "",
      Email: "",
      City: "",
      PhoneNumber: "",
      Message: "",
    },
  });

  const formSubmitHandler = async (data) => {
    console.log(data, "data");
    const response = await fetch(
      "https://script.google.com/macros/s/AKfycbzrKj67ogucxyyUolFP7quF7xmnQjDhG5_INi09obsqc0wHNujPA3UiSltffy2HKVhcWw/exec?action=addUser",
      {
        // redirect: "follow",
        mode: "no-cors",
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response, "response");
    if (response) {
      toast.success("Thanks for your Interest. We will get back to you soon.");
      reset();
    } else {
      toast.error("Something wrong, Please try again");
    }
  };
  return (
    <section className="contact-section">
      <div className="about-image-container">Contact Us</div>
      <div className="contact-heading-section">
        <h1 className="heading contact-heading">Contact For Any Query</h1>
      </div>
      <section className="contact-page">
        <div className="one">
          <div>
            <h2>Get in touch</h2>
            <p className="text">
              We love to hear from you. Our friendly team is always here to
              chat.
            </p>
          </div>
          <div className="item">
            <div>
              <RoomIcon className="icon" />
            </div>
            <div>
              <div className="sub-heading">Hyderabad Office</div>
              <a
                className="footer-email footer-link"
                href="mailto:sasexperts.in@gmail.com"
              >
                info@sasexperts.in
              </a>
              <p>
                203 & 204, 2nd floor, Babhukhan Mall, Somajiguda Circle,
                Hyderabad - 500082.
              </p>
              <a className="footer-link footer-email" href="tel:+917997001119">
                +91 799 7001 119
              </a>
            </div>
          </div>
          {/* <div className="item">
            <div>
              <RoomIcon className="icon" />
            </div>
            <div>
              <div className="sub-heading">Chennai Office</div>
              <p>viec.chennai1@vieceducation.com</p>
              <p>
                B7/C7, 1st Floor, Tirumurthy Nagar, Nungambakkam, Chennai,
                Tamilnadu (Parsn Complex) - 600034
              </p>
              <p>+91 984 1527 143</p>
            </div>
          </div> */}
        </div>
        <div className="two">
          <div className="form-container">
            <div className="contact-form-heading">Talk to our team</div>
            <Form className="form" onSubmit={handleSubmit(formSubmitHandler)}>
              <div className="flex">
                <Form.Group className="contact-row mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Controller
                    name="Name"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        id="name"
                        placeholder="Enter Full Name"
                        isInvalid={errors?.Name}
                      />
                    )}
                  />
                  {errors.Name && (
                    <span className="error">{errors.Name.message}</span>
                  )}
                </Form.Group>
                <Form.Group className=" contact-row mb-3">
                  <Form.Label className="label">Email address</Form.Label>
                  <Controller
                    name="Email"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="email"
                        id="Email"
                        className="formcontrol"
                        placeholder="Enter Email"
                        isInvalid={errors?.Email}
                      />
                    )}
                  />
                  {errors.Email && (
                    <span className="error">{errors.Email.message}</span>
                  )}
                </Form.Group>
              </div>
              <div className="flex">
                <Form.Group className=" contact-row mb-3">
                  <Form.Label>City</Form.Label>
                  <Controller
                    name="City"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        id="city"
                        className="formcontrol"
                        placeholder="Enter Your City"
                        isInvalid={errors?.City}
                      />
                    )}
                  />
                  {errors.City && (
                    <span className="error">{errors.City.message}</span>
                  )}
                </Form.Group>

                <Form.Group className="contact-row mb-3">
                  <Form.Label>Contact Number</Form.Label>
                  <Controller
                    name="PhoneNumber"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        id="PhoneNumber"
                        className="formcontrol"
                        placeholder="Enter Your Number"
                        isInvalid={errors?.PhoneNumber}
                      />
                    )}
                  />
                  {errors.PhoneNumber && (
                    <span className="error">{errors.PhoneNumber.message}</span>
                  )}
                </Form.Group>
              </div>
              <Form.Group className="mb-3">
                <Form.Label>How can we help?</Form.Label>
                <Controller
                  name="Message"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      as="textarea"
                      rows={3}
                      {...field}
                      type="text"
                      id="Message"
                      className="formcontrol"
                      placeholder="Tell us a little about your query..."
                    />
                  )}
                />
              </Form.Group>
              <div className="contact-btn-container">
                <button
                  style={{ background: "#54389E !important" }}
                  className="form-button contact-btn"
                  type="submit"
                  variant="primary"
                >
                  Book Your Appointment
                </button>
              </div>
            </Form>
          </div>
        </div>
      </section>
    </section>
  );
};
