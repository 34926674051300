import React, { useEffect } from "react";

export const Germany = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="country-section">
      <div className="country-img-container">
        <div className="country-top-title">Welcome to Germany</div>
        <p className="country-quote">Discover a World of Possibilities!</p>
      </div>
      <div className="countries-text-container">
        <div>
          <h3 className="country-subheading">About Germany:</h3>
          <p>
            Germany is a fascinating country that blends rich history,
            cutting-edge innovation, and captivating landscapes. From its
            vibrant cities to picturesque countryside, Germany offers a diverse
            range of experiences. Known for its efficiency, precision
            engineering, and strong economy, Germany is a hub of opportunity for
            those seeking education, career advancement, or a high standard of
            living. Immerse yourself in German culture, explore centuries-old
            castles, and indulge in world-renowned cuisine.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose Germany?</div>
        </div>
        <div>
          <h3 className="country-subheading">Education Excellence:</h3>
          <p>
            Germany is renowned for its exceptional education system. The
            country is home to numerous world-class universities and research
            institutions that offer a wide range of programs across various
            fields. Benefit from high-quality education, internationally
            recognized degrees, and a strong focus on research and innovation.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">
            Strong Economy and Career Opportunities:
          </h3>
          <p>
            Germany boasts a robust and diverse economy, offering abundant
            career opportunities in various sectors. Whether you&#39;re
            interested in engineering, automotive, technology, or finance,
            Germany provides a favourable environment for professional growth
            and success. The country is also known for its strong worker
            protection laws and competitive salaries.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Technological Innovation:</h3>
          <p>
            Germany is at the forefront of technological innovation and
            industrial development. It is known for its advanced engineering,
            manufacturing, and automation sectors. Join the ranks of leading
            technology companies, start-ups, and research institutions that are
            driving innovation and shaping the future.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Cultural Richness:</h3>
          <p>
            Germany is steeped in history, culture, and the arts. From the
            classical music of composers like Beethoven and Bach to world-class
            museums and galleries, Germany offers a wealth of cultural
            experiences. Explore charming villages, attend vibrant festivals,
            and immerse yourself in a society that values arts, literature, and
            intellectual pursuits.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Quality of Life:</h3>
          <p>
            Germany consistently ranks high in quality of life indexes. The
            country offers excellent healthcare, efficient infrastructure, and a
            well-developed social welfare system. Enjoy a high standard of
            living, clean and safe cities, and a strong commitment to
            environmental sustainability.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Our Services</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Study and Research:</h3>
          <p>
            We specialize in assisting students and researchers in pursuing
            their educational and research goals in Germany. Our services cover
            university and program selection, application processing, visa
            assistance, and support throughout your academic journey. We
            understand the unique requirements of the German education system,
            ensuring a smooth transition to your desired institution.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Work and Employment:</h3>
          <p>
            If you&#39;re considering Germany for career advancement, our
            services can help you navigate the job market and immigration
            pathways. We provide guidance on work permits, job search
            strategies, and professional networking opportunities to enhance
            your chances of securing employment in Germany&#39;s thriving job
            market.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Entrepreneurship and Business:</h3>
          <p>
            Germany offers excellent opportunities for entrepreneurs and
            business owners. Our services include guidance on business setup,
            investment opportunities, and entrepreneur visas. We assist with
            navigating the legal and regulatory requirements, enabling you to
            establish and grow your business in Germany.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Family Reunification:</h3>
          <p>
            We understand the importance of family unity. Our family
            reunification services help citizens and residents sponsor eligible
            family members, ensuring a smooth and timely reunification process.
            We assist with the documentation and procedures, prioritizing the
            well-being of your loved ones.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Settlement Support:</h3>
          <p>
            Moving to a new country can be a daunting process, but our dedicated
            team is here to support you in your settlement journey. We provide
            assistance with accommodation, language support, cultural
            orientation, and access to essential services. Our aim is to help
            you integrate smoothly into German society and feel at home.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose Our Services?</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Immigration Expertise:</h3>
          <p>
            Our experienced immigration consultants possess in-depth knowledge
            of the German immigration system. We stay updated with the latest
            regulations and policies to provide accurate and reliable advice
            tailored to your specific needs.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Personalized Approach:</h3>
          <p>
            We understand that each client has unique goals and circumstances.
            Our services are tailored to address your individual aspirations,
            ensuring that your immigration journey is smooth, efficient, and
            successful.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Comprehensive Support:</h3>
          <p>
            From the initial consultation to post-arrival assistance, we provide
            comprehensive support throughout the entire immigration process. We
            guide you through paperwork, documentation, and application
            submissions, while keeping you informed at every step.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Client Satisfaction:</h3>
          <p>
            Your satisfaction is our top priority. We are committed to providing
            exceptional service and have a track record of successful outcomes.
            Our testimonials and client reviews reflect our dedication to
            excellence and client-centric approach.
          </p>
          <br></br>
          <p>
            <i>
              Embark on an exciting adventure to Germany with our comprehensive
              immigration services. Contact us today to explore the world of
              possibilities that await you in this dynamic and culturally rich
              country.
            </i>
          </p>
        </div>
      </div>
    </div>
  );
};
