import React from "react";
import { useEffect } from "react";

export const Austrial = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="country-section">
      <div className="country-img-container">
        <div className="country-top-title">Welcome to Australia</div>
        <p className="country-quote">A Land of Extraordinary Opportunities!</p>
      </div>
      <div className="countries-text-container">
        {/* <div className="country-heading-container">
          <h1 className="heading">
            Welcome to Australia -{" "}
            <span className="country-quote">
              A Land of Extraordinary Opportunities!
            </span>
          </h1>
        </div> */}
        <div>
          <h3 className="country-subheading">About Australia</h3>
          <p>
            Australia is a vibrant and diverse country known for its
            breathtaking landscapes, rich cultural heritage, and world-class
            education and employment opportunities. From bustling cities to
            stunning natural wonders, Australia offers a unique and fulfilling
            experience for residents and visitors alike. Whether you&#39;re
            seeking an exceptional study destination, planning a memorable
            vacation, or considering permanent residency, Australia has
            something to offer everyone.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose Australia?</div>
        </div>
        <div>
          <h3 className="country-subheading">High-Quality Education:</h3>
          <p>
            Australia is renowned for its excellent education system and
            internationally recognized universities. With a wide range of
            programs and courses, students can pursue their academic ambitions
            in fields ranging from science and technology to arts and
            humanities.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Multicultural Society:</h3>
          <p>
            Australia is a melting pot of cultures, embracing diversity and
            fostering a welcoming environment for people from all walks of life.
            You&#39;ll have the opportunity to interact with people from various
            backgrounds and gain a global perspective.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Economic Opportunities:</h3>
          <p>
            Australia&#39;s strong economy and thriving industries offer
            abundant employment opportunities across diverse sectors. With a
            focus on innovation and entrepreneurship, the country provides a
            conducive environment for career growth and success.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Spectacular Landscapes:</h3>
          <p>
            From the breathtaking Great Barrier Reef to the iconic Sydney Opera
            House, Australia boasts incredible natural wonders and landmarks
            that will leave you in awe. Explore pristine beaches, ancient
            rainforests, vast deserts, and unique wildlife found nowhere else in
            the world.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">High Standard of Living:</h3>
          <p>
            With its excellent healthcare system, safe communities, and
            well-developed infrastructure, Australia consistently ranks among
            the top countries for quality of life. Enjoy a balanced lifestyle
            that promotes work-life balance and recreational activities.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Spectacular Landscapes:</h3>
          <p>
            From the breathtaking Great Barrier Reef to the iconic Sydney Opera
            House, Australia boasts incredible natural wonders and landmarks
            that will leave you in awe. Explore pristine beaches, ancient
            rainforests, vast deserts, and unique wildlife found nowhere else in
            the world.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Our Services</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Study Visa Assistance:</h3>
          <p>
            We specialize in helping aspiring students achieve their educational
            goals in Australia. Our dedicated team provides personalized
            guidance, from course selection and university applications to visa
            processing and pre-departure arrangements.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Visit Visa Services:</h3>
          <p>
            Experience the wonders of Australia with our Visit Visa services. We
            assist individuals and families in obtaining the necessary visa to
            explore the country&#39;s captivating attractions, visit loved ones,
            or attend business conferences.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Skilled Migration:</h3>
          <p>
            If you&#39;re seeking to establish a long-term presence in
            Australia, our skilled migration services can help you navigate the
            complex visa application process. We provide expert advice,
            comprehensive eligibility assessments, and support to enhance your
            chances of securing permanent residency.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Settlement Support:</h3>
          <p>
            Moving to a new country can be challenging, but our dedicated team
            is here to assist you every step of the way. We offer settlement
            services, including accommodation guidance, employment support, and
            cultural orientation, to help you seamlessly integrate into
            Australian society.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose Our Services?</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Expert Guidance:</h3>
          <p>
            Our experienced immigration consultants possess in-depth knowledge
            of the Australian immigration system. We stay updated with the
            latest policies and regulations to provide accurate and reliable
            advice tailored to your individual circumstances.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Personalized Approach:</h3>
          <p>
            We understand that each client is unique, and we take the time to
            understand your specific needs and aspirations. Our services are
            tailored to provide a personalized experience, ensuring that your
            journey to Australia is smooth and successful.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Comprehensive Support:</h3>
          <p>
            From the initial consultation to post-visa services, we provide
            end-to-end support throughout the entire immigration process. Our
            team is committed to addressing your queries, providing timely
            updates and ensuring a seamless experience from start to finish.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Client Satisfaction:</h3>
          <p>
            We prioritize client satisfaction and have a proven track record of
            successfully assisting individuals and families in achieving their
            Australian dreams. Our testimonials and positive reviews reflect our
            commitment to excellence in service delivery.
          </p>
        </div>
      </div>
    </div>
  );
};
