import React, { useEffect } from "react";

export const Ireland = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="country-section">
      <div className="country-img-container">
        <div className="country-top-title">Welcome to Ireland</div>
        <p className="country-quote">
          A Land of Charm, Culture, and Captivating Beauty!
        </p>
      </div>
      <div className="countries-text-container">
        <div>
          <h3 className="country-subheading">About Ireland:</h3>
          <p>
            Ireland, known as the &quot;Emerald Isle,&quot; is a country that
            captivates with its natural landscapes, rich history, vibrant
            culture, and warm hospitality. From its stunning coastal cliffs and
            ancient castles to its lively cities and lush green countryside,
            Ireland offers a unique blend of tradition and modernity. Whether
            you&#39;re seeking a world-class education, career opportunities, or
            a serene place to settle, Ireland has something to offer everyone.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose United States?</div>
        </div>
        <div>
          <h3 className="country-subheading">Rich Cultural Heritage:</h3>
          <p>
            Ireland is steeped in history and folklore, with a culture that
            embraces music, dance, literature, and traditional arts. Experience
            the lively atmosphere of Irish pubs, indulge in traditional cuisine,
            and celebrate festivals and events that showcase the country&#39;s
            vibrant spirit and heritage.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Natural Beauty:</h3>
          <p>
            Ireland&#39;s landscapes are renowned for their breathtaking beauty.
            Explore the dramatic Cliffs of Moher, wander through ancient
            forests, discover hidden lakes, and experience the enchanting beauty
            of the countryside. Ireland&#39;s diverse natural wonders provide
            endless opportunities for outdoor adventures and scenic exploration.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Education Excellence:</h3>
          <p>
            Ireland is home to internationally acclaimed universities and
            educational institutions that offer a wide range of programs and
            courses. Benefit from high-quality education, world-class research
            facilities, and a supportive learning environment that nurtures
            intellectual growth and creativity.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Thriving Tech Hub:</h3>
          <p>
            Ireland has emerged as a thriving hub for the tech industry,
            attracting global companies and start-ups alike. With a favourable
            business environment, a skilled workforce, and a strong focus on
            innovation, Ireland offers exciting career opportunities in
            technology, pharmaceuticals, finance, and more.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Welcoming and Safe:</h3>
          <p>
            The Irish people are known for their warm hospitality and
            friendliness, making Ireland a welcoming and inclusive destination.
            The country also enjoys a reputation for safety and security,
            providing peace of mind to residents and visitors alike.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Our Services</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Study Visa Assistance:</h3>
          <p>
            We specialize in assisting students in pursuing their educational
            goals in Ireland. Our experienced team provides comprehensive
            support, from university and course selection to visa processing and
            pre-departure arrangements. We ensure a seamless transition to your
            desired Irish educational institution.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Work and Employment:</h3>
          <p>
            If you&#39;re considering Ireland for career opportunities, our
            services can guide you through the immigration pathways. We provide
            guidance on work permits, employment schemes, and other relevant
            options, maximizing your chances of securing employment and thriving
            in the Irish job market.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Entrepreneurial Ventures:</h3>
          <p>
            Ireland welcomes entrepreneurial endeavors, offering avenues for
            business owners and investors. Our team provides guidance on
            entrepreneur visas, investment opportunities, and business setup
            procedures, assisting you in establishing and growing your business
            in Ireland.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Family Reunification:</h3>
          <p>
            Reunite with your loved ones in Ireland through our family
            reunification services. We assist Irish citizens and residents in
            sponsoring eligible family members, ensuring a smooth and timely
            reunification process.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Settlement Support:</h3>
          <p>
            Moving to a new country can be challenging, but our dedicated team
            is here to support you in your settlement journey. We provide
            assistance with accommodation, orientation, community integration,
            and access to essential services, ensuring a smooth transition to
            your new life in Ireland.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose Our Services?</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Immigration Expertise:</h3>
          <p>
            Our experienced immigration consultants possess in-depth knowledge
            of the Irish immigration system. We stay updated with the latest
            regulations and policies to provide accurate and reliable advice
            tailored to your specific needs.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Personalized Approach:</h3>
          <p>
            We understand that each client has unique goals and circumstances.
            Our services are tailored to address your individual aspirations,
            ensuring that your immigration journey is smooth, efficient, and
            successful.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Comprehensive Support:</h3>
          <p>
            From the initial consultation to post-arrival assistance, we provide
            comprehensive support throughout the entire immigration process. We
            guide you through paperwork, documentation, and application
            submissions, while keeping you informed at every step.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Client Satisfaction:</h3>
          <p>
            Your satisfaction is our top priority. We are committed to providing
            exceptional service and have a track record of successful outcomes.
            Our testimonials and client reviews reflect our dedication to
            excellence and client-centric approach.
          </p>
          <br></br>
          <p>
            <i>
              Embark on an extraordinary journey to Ireland with our
              comprehensive immigration services. Contact us today to explore
              the charm, culture, and captivating beauty that await you in this
              remarkable country.
            </i>
          </p>
        </div>
      </div>
    </div>
  );
};
