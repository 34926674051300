import React, { useState } from "react";
import "./Header.css";
import logo from "../../assests/images/Logo (2).png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FlagIcon from "@mui/icons-material/Flag";

const drawerWidth = 240;

export const Header = (props) => {
  const { window } = props;
  const navigate = useNavigate();
  const [aboutMenu, setAboutMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const dropdownClose = () => {
    setShowMenu(false);
  };

  const mobileDropdownClose = () => {
    setShowMenu(false);
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <div className="drawer-section">
      <div className="close-icon-container ">
        <CloseIcon onClick={handleDrawerToggle} />
      </div>
      <List>
        <ListItem
          onClick={() => {
            navigate("/");
            handleDrawerToggle();
          }}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>Home</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          onClick={() => {
            navigate("/aboutus");
            handleDrawerToggle();
          }}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText>About Us</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          onClick={() => {
            navigate("/ourteam");
            handleDrawerToggle();
          }}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText>Our Team</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          onClick={() => {
            navigate("/services");
            handleDrawerToggle();
          }}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <SupportAgentIcon />
            </ListItemIcon>
            <ListItemText>Services</ListItemText>
          </ListItemButton>
        </ListItem>
        {/* </a> */}
        <ListItem
          onClick={() => {
            navigate("/contactus");
            handleDrawerToggle();
          }}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <PermPhoneMsgIcon />
            </ListItemIcon>
            <ListItemText>Contact Us</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <FlagIcon />
            </ListItemIcon>
            <ListItemText>
              {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                label="Age"
                // onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select> */}
              <div className="mobile-dropdown">
                <button
                  onClick={() => setShowMenu(!showMenu)}
                  className="dropdown-btn mobile-navlink"
                >
                  Our Destinations{" "}
                  {showMenu ? (
                    <KeyboardArrowUpIcon className="mobile-header-icon" />
                  ) : (
                    <KeyboardArrowDownIcon className="mobile-header-icon" />
                  )}
                </button>
                {showMenu && (
                  <div id="myDropdown" class="mobile-dropdown-content">
                    <NavLink
                      onClick={mobileDropdownClose}
                      activeClassName="active-link"
                      className="mobile-navlink"
                      to="/austrila"
                    >
                      Australia
                    </NavLink>
                    <NavLink
                      onClick={mobileDropdownClose}
                      activeClassName="active-link"
                      className="mobile-navlink"
                      to="/canada"
                    >
                      Canada
                    </NavLink>
                    <NavLink
                      onClick={mobileDropdownClose}
                      activeClassName="active-link"
                      className="mobile-navlink"
                      to="/newzland"
                    >
                      New Zealand
                    </NavLink>
                    <NavLink
                      onClick={mobileDropdownClose}
                      activeClassName="active-link"
                      className="mobile-navlink"
                      to="/unitedkingdom"
                    >
                      United Kingdom
                    </NavLink>
                    <NavLink
                      onClick={mobileDropdownClose}
                      activeClassName="active-link"
                      className="mobile-navlink"
                      to="/usa"
                    >
                      USA
                    </NavLink>
                    <NavLink
                      onClick={mobileDropdownClose}
                      activeClassName="active-link"
                      className="mobile-navlink"
                      to="/ireland"
                    >
                      Ireland
                    </NavLink>
                    <NavLink
                      onClick={mobileDropdownClose}
                      activeClassName="active-link"
                      className="mobile-navlink"
                      to="/germany"
                    >
                      Germany
                    </NavLink>
                    <NavLink
                      onClick={mobileDropdownClose}
                      activeClassName="active-link"
                      className="mobile-navlink"
                      to="/singapore"
                    >
                      Singapore
                    </NavLink>
                    <NavLink
                      onClick={mobileDropdownClose}
                      activeClassName="active-link"
                      className="mobile-navlink"
                      to="/malaysia"
                    >
                      Malaysia
                    </NavLink>
                  </div>
                )}
              </div>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className="header-container">
      <section className="header">
        <div onClick={() => navigate("/home")}>
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div className="menu-container">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon className="menu-icon" />
          </IconButton>
        </div>

        <Box
          className="mobile-drawer"
          component="nav"
          sx={{ width: "70%" }}
          aria-label="mailbox folders"
        >
          <Drawer
            anchor="right"
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "60%",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <nav>
          <NavLink activeClassName="active-link" className="navlink" to="/">
            Home
          </NavLink>

          <NavLink
            activeClassName="active-link"
            className="navlink"
            to="/aboutus"
          >
            About Us
          </NavLink>
          <NavLink
            activeClassName="active-link"
            className="navlink"
            to="/ourteam"
          >
            Our Team
          </NavLink>
          <NavLink
            activeClassName="active-link"
            className="navlink"
            to="/services"
          >
            Services
          </NavLink>
          <div className="dropdown">
            <button
              onClick={() => setShowMenu(!showMenu)}
              className="dropdown-btn navlink"
            >
              Our Destinations{" "}
              {showMenu ? (
                <KeyboardArrowUpIcon className="header-icon" />
              ) : (
                <KeyboardArrowDownIcon className="header-icon" />
              )}
            </button>
            {showMenu && (
              <div id="myDropdown" class="dropdown-content">
                <NavLink
                  onClick={dropdownClose}
                  activeClassName="active-link"
                  className="navlink"
                  to="/austrila"
                >
                  Australia
                </NavLink>
                <NavLink
                  onClick={dropdownClose}
                  activeClassName="active-link"
                  className="navlink"
                  to="/canada"
                >
                  Canada
                </NavLink>
                <NavLink
                  onClick={dropdownClose}
                  activeClassName="active-link"
                  className="navlink"
                  to="/newzland"
                >
                  New Zealand
                </NavLink>
                <NavLink
                  onClick={dropdownClose}
                  activeClassName="active-link"
                  className="navlink"
                  to="/unitedkingdom"
                >
                  United Kingdom
                </NavLink>
                <NavLink
                  onClick={dropdownClose}
                  activeClassName="active-link"
                  className="navlink"
                  to="/usa"
                >
                  USA
                </NavLink>
                <NavLink
                  onClick={dropdownClose}
                  activeClassName="active-link"
                  className="navlink"
                  to="/ireland"
                >
                  Ireland
                </NavLink>
                <NavLink
                  onClick={dropdownClose}
                  activeClassName="active-link"
                  className="navlink"
                  to="/germany"
                >
                  Germany
                </NavLink>
                <NavLink
                  onClick={dropdownClose}
                  activeClassName="active-link"
                  className="navlink"
                  to="/singapore"
                >
                  Singapore
                </NavLink>
                <NavLink
                  onClick={dropdownClose}
                  activeClassName="active-link"
                  className="navlink"
                  to="/malaysia"
                >
                  Malaysia
                </NavLink>
              </div>
            )}
          </div>
          <NavLink
            activeClassName="active-link"
            className="navlink"
            to="/contactus"
          >
            Contact Us
          </NavLink>
        </nav>
      </section>
    </div>
  );
};
