import React, { useEffect } from "react";

export const USA = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="country-section">
      <div className="country-img-container">
        <div className="country-top-title">
          Welcome to the United States of America
        </div>
        <p className="country-quote">Land of Boundless Opportunities!</p>
      </div>
      <div className="countries-text-container">
        <div>
          <h3 className="country-subheading">About the United States:</h3>
          <p>
            The United States is a diverse and dynamic country known for its
            vast landscapes, thriving cities, rich cultural heritage, and
            unparalleled opportunities. From the iconic skylines of New York
            City and Los Angeles to the natural wonders of the Grand Canyon and
            Yellowstone National Park, the USA offers a captivating blend of
            urban sophistication and breathtaking beauty. Whether you&#39;re
            dreaming of a world-class education, exciting career prospects, or
            an immersive cultural experience, the United States has it all.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose United States?</div>
        </div>
        <div>
          <h3 className="country-subheading">Academic Excellence:</h3>
          <p>
            The USA is renowned for its top-tier educational institutions,
            offering a wide range of programs and courses across various fields.
            From Ivy League universities to state-of-the-art research centers,
            the country provides a high-quality education that fosters critical
            thinking, innovation, and global perspectives.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Professional Opportunities:</h3>
          <p>
            The United States offers a dynamic and robust economy with vast
            employment opportunities. From established industries to
            cutting-edge technologies, the country serves as a global hub for
            innovation and entrepreneurship. Benefit from a highly competitive
            job market and access to influential networks and resources.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Cultural Diversity:</h3>
          <p>
            The USA is a melting pot of cultures, welcoming people from all
            corners of the world. Experience a vibrant blend of traditions,
            languages, and cuisines that reflect the rich heritage of its
            diverse population. Engage with global communities and expand your
            worldview.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Natural Splendor:</h3>
          <p>
            The United States boasts diverse and stunning natural landscapes,
            ranging from towering mountains to pristine coastlines, vast
            deserts, and lush forests. Discover the wonders of national parks,
            embark on thrilling outdoor adventures, and witness the
            country&#39;s extraordinary biodiversity.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Innovation and Research:</h3>
          <p>
            The USA is at the forefront of technological advancements and
            scientific research. The country invests heavily in research and
            development, making it an ideal destination for professionals and
            scholars seeking to contribute to groundbreaking discoveries and
            innovations.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Our Services</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Study Visa Assistance:</h3>
          <p>
            We specialize in assisting students in pursuing their academic
            dreams in the United States. Our dedicated team provides
            comprehensive support, from selecting the right educational
            institution and program to guiding you through the visa application
            process. We ensure a seamless transition to your desired American
            university or college.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Work and Employment:</h3>
          <p>
            If you&#39;re considering the United States for career advancement,
            our services can help you navigate the immigration pathways. We
            provide guidance on work visas, employment sponsorship, and other
            relevant options, maximizing your chances of securing employment and
            thriving in the US job market.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Family Immigration:</h3>
          <p>
            Reunite with your loved ones in the United States through our family
            immigration services. We assist US citizens and permanent residents
            in sponsoring eligible family members, ensuring a smooth and timely
            reunification process.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">
            Investor and Entrepreneur Visas:
          </h3>
          <p>
            For individuals seeking entrepreneurial ventures or investment
            opportunities in the United States, we provide guidance on investor
            visas, business setup procedures, and access to networks and
            resources to help you establish your business and contribute to the
            US economy.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Settlement Support:</h3>
          <p>
            Moving to a new country can be challenging, but our dedicated team
            is here to support you in your settlement journey. We provide
            assistance with accommodation, orientation, community integration,
            and access to essential services, ensuring a smooth transition to
            your new life in the United States.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose Our Services?</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Immigration Expertise:</h3>
          <p>
            Our experienced immigration consultants possess in-depth knowledge
            of the US immigration system. We stay updated with the latest
            regulations and policies to provide accurate and reliable advice
            tailored to your specific needs.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Personalized Approach:</h3>
          <p>
            We understand that each client has unique goals and circumstances.
            Our services are tailored to address your individual aspirations,
            ensuring that your immigration journey is smooth, efficient, and
            successful.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Comprehensive Support:</h3>
          <p>
            From the initial consultation to post-arrival assistance, we provide
            comprehensive support throughout the entire immigration process. We
            guide you through paperwork, documentation, and application
            submissions, while keeping you informed at every step.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Client Satisfaction:</h3>
          <p>
            Your satisfaction is our top priority. We are committed to providing
            exceptional service and have a track record of successful outcomes.
            Our testimonials and client reviews reflect our dedication to
            excellence and client-centric approach.
          </p>
          <br></br>
          <p>
            <i>
              Embark on a transformative journey to the United States with our
              comprehensive immigration services. Contact us today to explore
              the boundless opportunities that await you in this land of
              possibilities.
            </i>
          </p>
        </div>
      </div>
    </div>
  );
};
