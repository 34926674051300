import React, { useEffect } from "react";
import "./Services.css";

export const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="services-page-section">
      <div className="service-image-container">Services</div>
      <div className="services-content-section">
        {/* <h3>Visa Services</h3> */}
        {/* <div></div> */}
        <div className="service-sub-section">
          <h6>Work Visa Services</h6>
          <p>
            Whether you're a skilled professional, entrepreneur, or looking for
            work experience abroad, our work visa services are designed to
            streamline the application process. Our team of immigration experts
            will guide you through the requirements, recommend suitable visa
            options, and provide comprehensive support to ensure a successful
            outcome. We'll help you navigate the complexities of work visa
            applications, compile the necessary documents, and meet all the
            deadlines, increasing your chances of approval.
          </p>
        </div>
        <div className="service-sub-section">
          <h6>Study Visa Services</h6>
          <p>
            Pursuing education abroad can be a transformative experience. Our
            study visa services are tailored to assist students in their
            academic journey. We'll help you understand the study visa
            requirements, guide you in selecting the right educational
            institution, and provide support throughout the application process.
            Our experienced team will ensure that you compile the necessary
            documents, meet deadlines, and submit a strong application,
            increasing your chances of obtaining a study visa and realizing your
            educational dreams.
          </p>
        </div>
        <div className="service-sub-section">
          <h6>Visit Visa Services</h6>
          <p>
            Whether you're planning a family visit, a vacation, or attending an
            event, our visit visa services will make your travel plans seamless.
            We'll guide you through the visit visa application process, provide
            assistance in compiling the required documents, and ensure that you
            meet all the necessary criteria. Our aim is to simplify the process,
            allowing you to focus on enjoying your trip and creating lasting
            memories with your loved ones.
          </p>
        </div>
        <div className="service-sub-section">
          <h6>Immigration Services</h6>
          <p>
            If you're considering building a new life in a foreign country, our
            immigration services are here to support you. We'll provide
            comprehensive guidance on immigration programs, assess your
            eligibility, and assist you throughout the application process.
            Whether you're seeking skilled migration, family sponsorship,
            business immigration, or other pathways, our experienced team will
            help you navigate the complexities and increase your chances of a
            successful immigration journey.
          </p>
        </div>
        <div className="service-sub-section">
          <h6>Counselling</h6>
          <ul>
            <li>
              Guidance and assistance to individuals or families navigating the
              immigration process.
            </li>
            <li>
              Offering assessments for clients’ chances of being approved for a
              visa.
            </li>
            <li>
              Preparing clients for interviews with different types of visa
              officers.
            </li>
            <li>
              Assisting clients in all facets of immigration, including family
              sponsorship, appeals, refugee claims, temporary residency, student
              immigration, and working.
            </li>
            <li>
              Provide guidance on the process and help clients strengthen their
              case.
            </li>
            <li>
              Offer an initial consultation to assess a client's immigration
              goals, eligibility, and available options. They provide
              information on different immigration programs, requirements, and
              procedures.
            </li>
          </ul>
          {/* <p>
            Whether you're planning a family visit, a vacation, or attending an
            event, our visit visa services will make your travel plans seamless.
            We'll guide you through the visit visa application process, provide
            assistance in compiling the required documents, and ensure that you
            meet all the necessary criteria. Our aim is to simplify the process,
            allowing you to focus on enjoying your trip and creating lasting
            memories with your loved ones.
          </p> */}
        </div>
        <div className="service-sub-section">
          <h6>Documentation</h6>
          <p>
            Assisting clients in preparing and completing the necessary
            application forms, gathering supporting documents, and ensuring all
            requirements are met accurately and in a timely manner.
          </p>
          <br></br>
          <p>
            Helping prepare clients’ immigration applications and paperwork, or
            proofreading them to ensure they are accurate and error-free. A
            review of your supporting documents to make sure all is accounted
            for and in order.
          </p>
          <br></br>
          <p>
            Review and organize clients' supporting documents, such as
            passports, identification papers, educational certificates, work
            experience letters, and financial records, to establish eligibility
            for immigration programs.
          </p>
        </div>
      </div>
    </div>
  );
};
