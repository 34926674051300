import React from "react";
import "./Services.css";
import visaImg from "../../assests/images/passport (1).png";
import { useNavigate } from "react-router";
import studyVisa from "../../assests/images/studyvisa.jpg";
import visitVisa from "../../assests/images/visitvisa.jpg";
import first from "../../assests/images/1.png";
import second from "../../assests/images/2.png";
import third from "../../assests/images/3.png";
import fourth from "../../assests/images/4.png";
import fifth from "../../assests/images/5.png";
import sixth from "../../assests/images/6.png";

export const Services = () => {
  const navigate = useNavigate();
  return (
    <section id="services" className="services-section">
      <h1 className="heading">Our Services</h1>
      <div className="services-container">
        <div className="service-card">
          <div className="service-card-image-container">
            <img src={first} alt="visa-logo" />
          </div>
          <div className="service-heading">Work Visa</div>
          <div className="service-text">
            Whether you're a skilled professional, entrepreneur, or looking for
            work experience abroad, our work visa services are designed to
            streamline the application process. Our team of immigration experts
            will guide you through the requirements, recommend suitable visa
            options, and provide comprehensive support to ensure a successful
            outcome. We'll help you navigate the complexities of work visa
            applications, compile the necessary documents, and meet all the
            deadlines, increasing your chances of approval.
          </div>
          <button className="card-btn" onClick={() => navigate("/services")}>
            Read More
          </button>
        </div>
        <div className="service-card">
          <div className="service-card-image-container">
            <img src={second} alt="visa-logo" />
          </div>
          <div className="service-heading">Study Visa</div>
          <div className="service-text">
            Pursuing education abroad can be a transformative experience. Our
            study visa services are tailored to assist students in their
            academic journey. We'll help you understand the study visa
            requirements, guide you in selecting the right educational
            institution, and provide support throughout the application process.
            Our experienced team will ensure that you compile the necessary
            documents, meet deadlines, and submit a strong application,
            increasing your chances of obtaining a study visa and realizing your
            educational dreams.
          </div>
          <button className="card-btn" onClick={() => navigate("/services")}>
            Read More
          </button>
        </div>
        <div className="service-card">
          <div className="service-card-image-container">
            <img src={third} alt="visa-logo" />
          </div>
          <div className="service-heading">Visit Visa</div>
          <div className="service-text">
            Whether you're planning a family visit, a vacation, or attending an
            event, our visit visa services will make your travel plans seamless.
            We'll guide you through the visit visa application process, provide
            assistance in compiling the required documents, and ensure that you
            meet all the necessary criteria. Our aim is to simplify the process,
            allowing you to focus on enjoying your trip and creating lasting
            memories with your loved ones.
          </div>
          <button className="card-btn" onClick={() => navigate("/services")}>
            Read More
          </button>
        </div>
        <div className="service-card">
          <div className="service-card-image-container">
            <img src={fourth} alt="visa-logo" />
          </div>
          <div className="service-heading">Immigration</div>
          <div className="service-text">
            If you're considering building a new life in a foreign country, our
            immigration services are here to support you. We'll provide
            comprehensive guidance on immigration programs, assess your
            eligibility, and assist you throughout the application process.
            Whether you're seeking skilled migration, family sponsorship,
            business immigration, or other pathways, our experienced team will
            help you navigate the complexities and increase your chances of a
            successful immigration journey.
          </div>
          <button className="card-btn" onClick={() => navigate("/services")}>
            Read More
          </button>
        </div>
        <div className="service-card">
          <div className="service-card-image-container">
            <img src={fifth} alt="visa-logo" />
          </div>
          <div className="service-heading">Post Landing Service</div>
          <div className="service-text">
            Some other post-landing services that will be included in the
            Immigration Services include arranging transport and accommodation
            for initial days in the foreign country and getting you connected
            with our past clients already living in the foreign country.
          </div>
          <button className="card-btn" onClick={() => navigate("/services")}>
            Read More
          </button>
        </div>
        <div className="service-card">
          <div className="service-card-image-container">
            <img src={sixth} alt="visa-logo" />
          </div>
          <div className="service-heading">Job Assistance</div>
          <div className="service-text">
            Job Assistance We will help you throughout the Immigration Process
            of your country of dreams. This also assisting you with a passive
            job search on the foreign land through our established contacts.
          </div>
          <button className="card-btn" onClick={() => navigate("/services")}>
            Read More
          </button>
        </div>
      </div>
    </section>
  );
};
