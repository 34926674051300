import React, { useEffect } from "react";

export const Canada = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="country-section">
      <div className="country-img-container">
        <div className="country-top-title">Welcome to Canada</div>
        <p className="country-quote">A Land of Possibilities!</p>
      </div>
      <div className="countries-text-container">
        {/* <div className="country-heading-container">
      <h1 className="heading">
        Welcome to Australia -{" "}
        <span className="country-quote">
          A Land of Extraordinary Opportunities!
        </span>
      </h1>
    </div> */}
        <div>
          <h3 className="country-subheading">About Canada:</h3>
          <p>
            Canada is a diverse and welcoming country known for its natural
            beauty, rich cultural heritage, and exceptional quality of life.
            With its vibrant cities, stunning landscapes, and thriving economy,
            Canada offers countless opportunities for individuals and families
            looking to study, work, or settle in a safe and inclusive
            environment. Whether you&#39;re seeking world-class education,
            career growth, or a fulfilling lifestyle, Canada has it all.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose Canada?</div>
        </div>
        <div>
          <h3 className="country-subheading">High-Quality Education:</h3>
          <p>
            Canada is home to renowned universities and colleges that offer
            top-notch education across various fields. With a focus on research
            and innovation, Canadian institutions provide students with valuable
            skills and knowledge to succeed in their chosen careers.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Economic Opportunities:</h3>
          <p>
            Canada&#39;s robust economy and diverse industries make it a prime
            destination for individuals seeking employment or entrepreneurial
            ventures. With a strong job market and a supportive business
            environment, Canada offers numerous opportunities for professional
            growth and success.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Multicultural Society:</h3>
          <p>
            Canada is celebrated for its multiculturalism and inclusivity. With
            a rich tapestry of cultures, languages, and traditions, Canada
            embraces diversity and promotes a harmonious society where
            individuals from all backgrounds can thrive.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Safety and Security:</h3>
          <p>
            Canada consistently ranks among the safest countries in the world.
            Its low crime rates, stable political environment, and
            well-developed infrastructure provide peace of mind to residents and
            make it an ideal place to raise a family.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Natural Splendour:</h3>
          <p>
            From the majestic Rocky Mountains to the stunning coastlines and
            vast forests, Canada&#39;s breathtaking landscapes offer endless
            opportunities for outdoor adventures and exploration. Experience the
            wonders of nature, including national parks, wildlife, and pristine
            lakes.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Our Services</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Study Visa Assistance:</h3>
          <p>
            We specialize in helping students pursue their academic aspirations
            in Canada. Our expert team provides comprehensive guidance, from
            choosing the right program and institution to assisting with the
            visa application process, ensuring a smooth transition to Canadian
            education.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Work &amp; Skilled Migration:</h3>
          <p>
            If you&#39;re considering Canada as a place to work and build a
            successful career, our skilled migration services can help you
            navigate the immigration pathways. We provide guidance on work
            permits, provincial nominee programs, and Express Entry, maximizing
            your chances of securing employment and permanent residency.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Family Sponsorship:</h3>
          <p>
            Reunite with your loved ones in Canada through our family
            sponsorship services. We assist Canadian citizens and permanent
            residents in sponsoring their eligible family members, ensuring a
            smooth and timely reunification process.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Settlement Support:</h3>
          <p>
            Moving to a new country can be overwhelming, but our dedicated team
            is here to support you in your settlement journey. We offer services
            such as pre-arrival orientation, finding accommodation, job search
            assistance, and access to community resources, helping you adapt to
            your new life in Canada.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose Our Services?</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Immigration Expertise:</h3>
          <p>
            Our team of experienced immigration consultants has in-depth
            knowledge of the Canadian immigration system. We stay up to date
            with the latest regulations and policies to provide accurate and
            reliable advice tailored to your specific needs.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Personalized Approach:</h3>
          <p>
            We understand that every client is unique, and we take the time to
            understand your goals and aspirations. Our services are tailored to
            provide a personalized experience, ensuring that your immigration
            journey is smooth, efficient, and successful.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Comprehensive Support:</h3>
          <p>
            From the initial consultation to post-arrival assistance, we provide
            comprehensive support throughout the entire immigration process. We
            guide you through paperwork, documentation, and application
            submissions, while keeping you informed at every step.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Client Satisfaction:</h3>
          <p>
            Our ultimate goal is your satisfaction. We strive to provide
            exceptional service and have a track record of successful outcomes.
            Our testimonials and client reviews reflect our commitment to
            excellence and client-centric approach.
          </p>
          <br></br>
          <p>
            <i>
              Embark on an exciting journey to Canada with our comprehensive
              immigration services. Contact us today to explore the endless
              possibilities that await you in this extraordinary country.
            </i>
          </p>
        </div>
      </div>
    </div>
  );
};
