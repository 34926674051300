import React from "react";
import "./Introduction.css";
import { useNavigate } from "react-router";
import usaImg from "../../assests/images/country-usa.jpg";
import canadaImg from "../../assests/images/country_canada.jpg";
import newzlandImg from "../../assests/images/country_newzealand.jpg";
import ukImg from "../../assests/images/country_uk.jpg";
import ausImg from "../../assests/images/country_aus.jpg";
import irelandImg from "../../assests/images/ireland.jpg";
import malaysiaImg from "../../assests/images/malaysiacountry.jpg";
import dubaiImg from "../../assests/images/germanycountry.jpg";
import singaporeImg from "../../assests/images/singaporecountry.jpg";

export const Introduction = () => {
  const navigate = useNavigate();
  return (
    <section className="Introduction-section">
      <h1 className="heading">Our Destinations</h1>
      {/* <div className="container">
      <div className="one">
        <p className="text">
          Our experts take you through the process of immigration and ensure
          you land in your dream destination. Our expertise lies in making you
          understand the entire process and living the promise made to you. We
          are transparent in leading you through step by step process in a
          naive way that is easy to understand and follow. Our team never
          missed chasing and achieving success for us and our clients.
        </p>
      </div> */}
      {/* <div className="two">
      <iframe
        className="introduction-video"
        src="https://www.youtube.com/embed/420EAEwkDII"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        style={{ borderRadius: "20px" }}
      ></iframe>
    </div> */}
      {/* </div> */}
      {/* <div> */}
      {/* <div className="subheading">
      We made our mark in the business of immigration and student
      recruitment for popular destination like...
    </div>  */}
      <div className="country-container">
        <div className="country-item">
          <div>
            <img className="country-logo" src={ausImg} alt="country-logo" />
          </div>
          <div className="country-text-container">
            <div className="country-title">Australia</div>
            <div className="country-text">
              We will help you in every step of the Visa application process.
            </div>
            <button
              onClick={() => navigate("/austrila")}
              className="second-btn"
            >
              Know more
            </button>
          </div>
        </div>
        <div className="country-item">
          <div className="img-container">
            <img className="country-logo" src={canadaImg} alt="country-logo" />
          </div>
          <div className="country-text-container">
            <div className="country-title">Canada</div>
            <div className="country-text">
              We will help you in every step of the Visa application process.
            </div>
            <button onClick={() => navigate("/canada")} className="second-btn">
              Know more
            </button>
          </div>
        </div>
        <div className="country-item">
          <div>
            <img
              className="country-logo"
              src={newzlandImg}
              alt="country-logo"
            />
          </div>
          <div className="country-text-container">
            <div className="country-title">New Zealand</div>
            <div className="country-text">
              We will help you in every step of the Visa application process.
            </div>
            <button
              onClick={() => navigate("/newzland")}
              className="second-btn"
            >
              Know more
            </button>
          </div>
        </div>
        <div className="country-item">
          <div>
            <img className="country-logo" src={ukImg} alt="country-logo" />
          </div>
          <div className="country-text-container">
            <div className="country-title">United Kingdom</div>
            <div className="country-text">
              We will help you in every step of the Visa application process.
            </div>
            <button
              onClick={() => navigate("/unitedkingdom")}
              className="second-btn"
            >
              Know more
            </button>
          </div>
        </div>
        <div className="country-item">
          <div>
            <img className="country-logo" src={usaImg} alt="country-logo" />
          </div>
          <div className="country-text-container">
            <div className="country-title">United States of America</div>
            <div className="country-text">
              We will help you in every step of the Visa application process.
            </div>
            <button onClick={() => navigate("/usa")} className="second-btn">
              Know more
            </button>
          </div>
        </div>
        <div className="country-item">
          <div>
            <img className="country-logo" src={irelandImg} alt="country-logo" />
          </div>
          <div className="country-text-container">
            <div className="country-title">Ireland</div>
            <div className="country-text">
              We will help you in every step of the Visa application process.
            </div>
            <button onClick={() => navigate("/ireland")} className="second-btn">
              Know more
            </button>
          </div>
        </div>
        <div className="country-item">
          <div>
            <img className="country-logo" src={dubaiImg} alt="country-logo" />
          </div>
          <div className="country-text-container">
            <div className="country-title">Germany</div>
            <div className="country-text">
              We will help you in every step of the Visa application process.
            </div>
            <button onClick={() => navigate("/germany")} className="second-btn">
              Know more
            </button>
          </div>
        </div>
        <div className="country-item">
          <div>
            <img
              className="country-logo"
              src={singaporeImg}
              alt="country-logo"
            />
          </div>
          <div className="country-text-container">
            <div className="country-title">Singapore</div>
            <div className="country-text">
              We will help you in every step of the Visa application process.
            </div>
            <button
              onClick={() => navigate("/singapore")}
              className="second-btn"
            >
              Know more
            </button>
          </div>
        </div>
        <div className="country-item">
          <div>
            <img
              className="country-logo"
              src={malaysiaImg}
              alt="country-logo"
            />
          </div>
          <div className="country-text-container">
            <div className="country-title">Malaysia</div>
            <div className="country-text">
              We will help you in every step of the Visa application process.
            </div>
            <button
              onClick={() => navigate("/malaysia")}
              className="second-btn"
            >
              Know more
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
