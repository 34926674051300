import React, { useEffect } from "react";

export const Newzland = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="country-section">
      <div className="country-img-container">
        <div className="country-top-title">Welcome to New Zealand</div>
        <p className="country-quote">
          A Land of Enchanting Beauty and Endless Adventures!
        </p>
      </div>
      <div className="countries-text-container">
        {/* <div className="country-heading-container">
      <h1 className="heading">
        Welcome to Australia -{" "}
        <span className="country-quote">
          A Land of Extraordinary Opportunities!
        </span>
      </h1>
    </div> */}
        <div>
          <h3 className="country-subheading">About New Zealand:</h3>
          <p>
            Nestled in the south-western Pacific Ocean, New Zealand is a country
            of breathtaking landscapes, vibrant culture, and warm-hearted
            people. Known for its stunning fjords, majestic mountains, pristine
            beaches, and lush greenery, New Zealand offers a unique and
            unparalleled experience for residents and visitors alike. Whether
            you&#39;re seeking a world-class education, an adventurous
            lifestyle, or a serene place to settle, New Zealand has it all.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose New Zealand?</div>
        </div>
        <div>
          <h3 className="country-subheading">Natural Wonders:</h3>
          <p>
            New Zealand is a nature lover&#39;s paradise. From the awe-inspiring
            Milford Sound to the geothermal wonders of Rotorua and the
            breathtaking peaks of the Southern Alps, the country boasts an
            incredible array of landscapes. Immerse yourself in the beauty of
            national parks, explore hiking trails, or indulge in thrilling
            adventure sports.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Quality Education:</h3>
          <p>
            New Zealand is renowned for its excellent education system. The
            country&#39;s universities, institutes, and schools offer a wide
            range of courses and programs, providing students with globally
            recognized qualifications and a platform for success. Benefit from a
            supportive learning environment and gain valuable skills for your
            future.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Welcoming Culture:</h3>
          <p>
            Known for its warm and friendly people, New Zealand embraces
            diversity and celebrates its multicultural heritage. Experience the
            unique Māori culture, partake in traditional ceremonies, and enjoy
            the vibrant arts and music scene. You&#39;ll find a sense of
            belonging and acceptance in this inclusive society.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Work and Lifestyle Balance:</h3>
          <p>
            New Zealand is committed to fostering a healthy work-life balance.
            With its relaxed pace of life, low population density, and emphasis
            on outdoor activities, the country offers a lifestyle that promotes
            well-being and a strong connection with nature. Enjoy a balanced and
            fulfilling life in New Zealand.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Safe and Peaceful Environment:</h3>
          <p>
            New Zealand consistently ranks among the safest countries globally.
            Its stable political system, low crime rates, and high-quality
            healthcare ensure a safe and secure environment for residents.
            It&#39;s an ideal place to raise a family and create lasting
            memories.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Our Services</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Study Visa Assistance:</h3>
          <p>
            We specialize in assisting students in pursuing their educational
            dreams in New Zealand. Our dedicated team provides comprehensive
            support, from selecting the right course and institution to
            navigating the visa application process. We ensure a seamless
            transition to your desired New Zealand educational institution.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Skilled Migration:</h3>
          <p>
            If you&#39;re considering New Zealand as a destination for work and
            career opportunities, our skilled migration services can help you
            navigate the immigration pathways. We provide guidance on skilled
            migrant visas, work permits, and other options, maximizing your
            chances of securing employment and permanent residency.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Entrepreneurial Ventures:</h3>
          <p>
            New Zealand encourages entrepreneurship and offers avenues for
            aspiring business owners. Our team provides guidance on entrepreneur
            visas, investment opportunities, and business setup procedures,
            helping you establish your entrepreneurial venture in New Zealand.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Settlement Support:</h3>
          <p>
            Moving to a new country can be daunting, but our dedicated team is
            here to support you in your settlement journey. We provide valuable
            assistance with accommodation, job search, community integration,
            and access to essential services, ensuring a smooth transition to
            your new life in New Zealand.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose Our Services?</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Expert Guidance:</h3>
          <p>
            Our experienced immigration consultants possess in-depth knowledge
            of the New Zealand immigration system. We stay updated with the
            latest regulations and policies, providing accurate and reliable
            advice tailored to your specific needs.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Personalized Approach:</h3>
          <p>
            We understand that every client is unique, and we take the time to
            understand your goals and aspirations. Our services are tailored to
            provide a personalized experience, ensuring that your immigration
            journey is smooth, efficient, and successful.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Comprehensive Support:</h3>
          <p>
            From the initial consultation to post-arrival assistance, we provide
            comprehensive support throughout the entire immigration process. We
            guide you through paperwork, documentation, and application
            submissions, while keeping you informed at every step.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Client Satisfaction:</h3>
          <p>
            Your satisfaction is our top priority. We are committed to providing
            exceptional service and have a track record of successful outcomes.
            Our testimonials and client reviews reflect our dedication to
            excellence and client-centric approach.
          </p>
          <br></br>
          <p>
            <i>
              Embark on an extraordinary journey to New Zealand with our
              comprehensive immigration services. Contact us today to explore
              the limitless possibilities that await you in this captivating
              country of natural wonders and remarkable opportunities.
            </i>
          </p>
        </div>
      </div>
    </div>
  );
};
