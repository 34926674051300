import React from "react";
import Slider from "react-slick";
// import banner1 from "../../assests/images/banner1.jpeg";
import banner2 from "../../assests/images/newbanner.jpg";
import banner3 from "../../assests/images/banner3.jpg";
import banner4 from "../../assests/images/newbanner5.jpg";
import newbanner1 from "../../assests/images/BANNER4.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carsoul.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const CustomArrows = ({ onClick, className, icon }) => {
  return (
    <div className={className} onClick={onClick}>
      {icon}
    </div>
  );
};

export const Carsoul = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: (
      <CustomArrows icon={<ArrowBackIosNewIcon className="slick-icon" />} />
    ),
    nextArrow: (
      <CustomArrows icon={<ArrowForwardIosIcon className="slick-icon" />} />
    ),
  };
  return (
    <div className="carsoul-section">
      <Slider {...settings}>
        <div>
          <img className="bannerImg" src={newbanner1} alt="logo" />
        </div>
        <div>
          <img className="bannerImg" src={banner2} alt="logo" />
        </div>
        <div>
          <img className="bannerImg" src={banner3} alt="logo" />
        </div>
        <div>
          <img className="bannerImg" src={banner4} alt="logo" />
        </div>
      </Slider>
    </div>
  );
};
