import React, { useEffect } from "react";

export const UnitedKingdon = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="country-section">
      <div className="country-img-container">
        <div className="country-top-title">Welcome to the United Kingdom</div>
        <p className="country-quote">Where Tradition Meets Innovation!</p>
      </div>
      <div className="countries-text-container">
        {/* <div className="country-heading-container">
      <h1 className="heading">
        Welcome to Australia -{" "}
        <span className="country-quote">
          A Land of Extraordinary Opportunities!
        </span>
      </h1>
    </div> */}
        <div>
          <h3 className="country-subheading">About the United Kingdom:</h3>
          <p>
            The United Kingdom, comprised of England, Scotland, Wales, and
            Northern Ireland, is a captivating blend of rich history, diverse
            culture, and thriving modernity. With its iconic landmarks,
            world-class universities, bustling cities, and picturesque
            countryside, the UK offers a remarkable experience for residents and
            visitors alike. Whether you&#39;re drawn to its historic sites,
            vibrant arts scene, exceptional education, or professional
            opportunities, the UK has something for everyone.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose United Kingdom?</div>
        </div>
        <div>
          <h3 className="country-subheading">Renowned Education:</h3>
          <p>
            The UK is globally recognized for its prestigious educational
            institutions. From ancient universities like Oxford and Cambridge to
            modern, research-driven universities, the UK offers a wide range of
            programs and courses across various disciplines. Gain a high-quality
            education that opens doors to global opportunities.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Cultural Heritage:</h3>
          <p>
            Immerse yourself in the rich cultural tapestry of the UK. Explore
            centuries-old castles, visit world-class museums, attend theatre
            performances in London&#39;s West End, or indulge in traditional
            culinary delights. The UK&#39;s diverse heritage and vibrant arts
            scene offer a unique and captivating experience.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Global Opportunities:</h3>
          <p>
            The UK provides a wealth of professional opportunities across
            industries. Its thriving economy, strong business networks, and
            entrepreneurial spirit make it an attractive destination for career
            growth and innovation. Benefit from its connections to global
            markets and multinational corporations.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Natural Beauty:</h3>
          <p>
            From the charming English countryside to the breathtaking landscapes
            of Scotland and Wales, the UK offers stunning natural beauty at
            every turn. Explore national parks, hike scenic trails, or relax on
            picturesque coastlines. Experience the harmonious blend of urban
            sophistication and natural serenity.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Travel Hub:</h3>
          <p>
            With its well-connected airports and efficient transportation
            systems, the UK serves as a gateway to Europe and beyond. Explore
            the continent, discover new cultures, and create lifelong memories
            with convenient access to a plethora of international destinations.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Our Services</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Study Visa Assistance:</h3>
          <p>
            We specialize in helping students achieve their educational
            aspirations in the UK. Our expert team provides comprehensive
            support, from course selection and university applications to visa
            processing and pre-departure arrangements. We ensure a smooth
            transition to your desired UK educational institution.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Work and Skilled Migration:</h3>
          <p>
            If you&#39;re considering the UK for employment or career
            advancement, our skilled migration services can guide you through
            the immigration pathways. We provide guidance on work permits, Tier
            2 visas, and other relevant options, maximizing your chances of
            securing employment and settling in the UK.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Family Sponsorship:</h3>
          <p>
            Reunite with your loved ones in the UK through our family
            sponsorship services. We assist UK citizens and residents in
            sponsoring eligible family members, ensuring a smooth and timely
            reunification process.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Settlement Support:</h3>
          <p>
            Moving to a new country can be overwhelming, but our dedicated team
            is here to support you in your settlement journey. We provide
            assistance with accommodation, orientation, community integration,
            and access to essential services, ensuring a seamless transition to
            your new life in the UK.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose Our Services?</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Immigration Expertise:</h3>
          <p>
            Our experienced immigration consultants possess in-depth knowledge
            of the UK immigration system. We stay updated with the latest
            regulations and policies to provide accurate and reliable advice
            tailored to your specific needs.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Personalized Approach:</h3>
          <p>
            We understand that each client has unique goals and circumstances.
            Our services are tailored to address your individual aspirations,
            ensuring that your immigration journey is smooth, efficient, and
            successful.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Comprehensive Support:</h3>
          <p>
            From the initial consultation to post-arrival assistance, we provide
            comprehensive support throughout the entire immigration process. We
            guide you through paperwork, documentation, and application
            submissions, while keeping you informed at every step.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Client Satisfaction:</h3>
          <p>
            Your satisfaction is our top priority. We are committed to providing
            exceptional service and have a track record of successful outcomes.
            Our testimonials and client reviews reflect our dedication to
            excellence and client-centric approach.
          </p>
          <br></br>
          <p>
            <i>
              Embark on an exciting journey to the United Kingdom with our
              comprehensive immigration services. Contact us today to explore
              the remarkable opportunities that await you in this captivating
              country of tradition and innovation.
            </i>
          </p>
        </div>
      </div>
    </div>
  );
};
