import React, { useEffect } from "react";
import "./AboutUs.css";
import exportImg from "../../assests/images/export.jpg";
import canFlag from "../../assests/images/candaFlag.png";
import ausFlag from "../../assests/images/ausFlag.webp";
import ukFlag from "../../assests/images/ukflag.webp";
import irelandFlag from "../../assests/images/Irland.png";
import newzlandFlag from "../../assests/images/newzlandflag.webp";
import singaporeFlag from "../../assests/images/singaporeflag.jpg";
import malaysiaFlag from "../../assests/images/malaysiaflag.jpg";
import dubaiFlag from "../../assests/images/germayFlag.webp";
import usaFlag from "../../assests/images/usaFlag.webp";
import { useNavigate } from "react-router";

export const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  return (
    <section className="about-section">
      <div className="about-image-container">About Us</div>
      <div className="company-info">
        <div className="first-container">
          <div className="heading-container">
            <h2 className="about-title heading">SAS Experts LLP</h2>
          </div>
          <p className="about-txt">
            Our experts take you through the process of immigration and ensure
            you land in your dream destination. Our expertise lies in making you
            understand the entire process and living the promise made to you. We
            are transparent in leading you through step by step process in a
            naive way that is easy to understand and follow. Our team never
            missed chasing and achieving success for us and our clients. We made
            our mark in the business of immigration and student recruitment for
            popular destination like
          </p>
          <ul className="about-list">
            <li onClick={() => navigate("/austrila")} className="about-country">
              <img className="country-flag" src={ausFlag} alt="" />
              <p>Austrailia</p>
            </li>
            <li onClick={() => navigate("/usa")} className="about-country">
              <img className="country-flag" src={usaFlag} alt="" />
              <p>USA</p>
            </li>
            <li
              onClick={() => navigate("/unitedkingdom")}
              className="about-country"
            >
              <img className="country-flag" src={ukFlag} alt="" />
              <p>UK</p>
            </li>
            <li onClick={() => navigate("/canada")} className="about-country">
              <img className="country-flag" src={canFlag} alt="" />
              <p>Canada</p>
            </li>
            <li onClick={() => navigate("/newzland")} className="about-country">
              <img className="country-flag" src={newzlandFlag} alt="" />
              <p>New Zealand</p>
            </li>
            <li onClick={() => navigate("/ireland")} className="about-country">
              <img className="country-flag" src={irelandFlag} alt="" />
              <p>Ireland</p>
            </li>
            <li onClick={() => navigate("/malaysia")} className="about-country">
              <img className="country-flag" src={malaysiaFlag} alt="" />
              <p>Malaysia</p>
            </li>
            <li
              onClick={() => navigate("/singapore")}
              className="about-country"
            >
              <img className="country-flag" src={singaporeFlag} alt="" />
              <p>Singapore</p>
            </li>
            <li onClick={() => navigate("/germany")} className="about-country">
              <img className="country-flag" src={dubaiFlag} alt="" />
              <p>Germany</p>
            </li>
          </ul>
        </div>
        {/* <div className="second-container">
          <img src={exportImg} alt="logo" />
        </div> */}
      </div>

      <div className="guidence-section">
        <h4>Guidance</h4>
        <p className="about-txt">
          Our advisors read through minute details and evaluate each profile to
          research and identify the best available option with land of
          opportunities for people who are aspiring to migrate. We pay utmost
          attention to each individual and share timely information and address
          every query with the same intent irrespective of their magnitude. Our
          immigration experts explain and follow all regulatory guidelines and
          complete documentation to process and acquire all permits needed to
          facilitate your immigration to desired country. We invest time and
          energy to ensure you and your family have a bright future in your
          dream destination.
        </p>
      </div>
    </section>
  );
};
