import React from "react";
import profileImg from "../../assests/images/about-contact-left.jpg";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import "./Callback.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

export const Callback = () => {
  const validationSchema = yup.object({
    Name: yup.string().required("Name is required"),
    Email: yup
      .string()
      .required("Email is required")
      .email("Enter valid Email address"),
    PhoneNumber: yup
      .string()
      .required("Phone number is required")
      .min(10, "Enter valid phone number")
      .max(10, "Enter valid phone number")
      .matches("^[0-9]*$", "Enter valid phone number"),
    City: yup.string().required("City is required"),
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    defaultValues: {
      Name: "",
      Email: "",
      PhoneNumber: "",
      City: "",
      Age: "",
      Qualification: "",
      VisaType: "",
      CountryIntersted: "",
      Message: "",
    },
  });

  const formSubmitHandler = async (data) => {
    const response = await fetch(
      "https://script.google.com/macros/s/AKfycbzrKj67ogucxyyUolFP7quF7xmnQjDhG5_INi09obsqc0wHNujPA3UiSltffy2HKVhcWw/exec?action=addUser",
      {
        // redirect: "follow",
        mode: "no-cors",
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response) {
      toast.success("Thanks for your Interest. We will get back to you soon.");
      reset();
    } else {
      toast.error("Something wrong, Please try again");
    }
  };
  return (
    <section className="callback-section">
      <div className="callback-one">
        <img src={profileImg} alt="photo" />
      </div>
      <Form className="callback-two" onSubmit={handleSubmit(formSubmitHandler)}>
        <h1 className="heading callback-haeding">Request a Call Back</h1>
        <p>
          Consult with our experienced immigration consultants and move to your
          dream country. Just fill the Enquiry form and you will Receive a Call
          from one of our Experts within 24 Hours
        </p>
        <div className="form-item-container">
          <div className="form-item">
            <Form.Group className="mb-3">
              <Form.Label>Full Name *</Form.Label>
              <Controller
                name="Name"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    id="Name"
                    placeholder="Enter Full Name"
                    isInvalid={errors?.Name}
                  />
                )}
              />
              {errors.Name && (
                <span className="error">{errors.Name.message}</span>
              )}
            </Form.Group>
          </div>
          <div className="form-item">
            <Form.Group className="mb-3">
              <Form.Label className="label">Email *</Form.Label>
              <Controller
                name="Email"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="email"
                    id="Email"
                    className="formcontrol"
                    placeholder="Enter Email"
                    isInvalid={errors?.Email}
                  />
                )}
              />
              {errors.Email && (
                <span className="error">{errors.Email.message}</span>
              )}
            </Form.Group>
          </div>
        </div>
        <div className="form-item-container">
          <div className="form-item">
            <Form.Group className="mb-3">
              <Form.Label>Phone Number *</Form.Label>
              <Controller
                name="PhoneNumber"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    id="PhoneNumber"
                    className="formcontrol"
                    placeholder="Enter Your Number"
                    isInvalid={errors?.PhoneNumber}
                  />
                )}
              />
              {errors.PhoneNumber && (
                <span className="error">{errors.PhoneNumber.message}</span>
              )}
            </Form.Group>
          </div>
          <div className="form-item">
            <Form.Group className="mb-3">
              <Form.Label>City *</Form.Label>
              <Controller
                name="City"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    id="City"
                    className="formcontrol"
                    placeholder="Enter Your City"
                    isInvalid={errors?.City}
                  />
                )}
              />
              {errors.City && (
                <span className="error">{errors.City.message}</span>
              )}
            </Form.Group>
          </div>
        </div>
        <div className="form-item-container">
          <div className="form-item">
            <Form.Group className="mb-3">
              <Form.Label>Age</Form.Label>
              <Controller
                name="Age"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    id="Age"
                    className="formcontrol"
                    placeholder="Enter Your Age"
                    isInvalid={errors?.Age}
                  />
                )}
              />
              {errors.Age && (
                <span className="error">{errors.Age.message}</span>
              )}
            </Form.Group>
          </div>
          <div className="form-item">
            <Form.Group className="mb-3">
              <Form.Label>Qualification</Form.Label>
              <Controller
                name="Qualification"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    id="Qualification"
                    className="formcontrol"
                    placeholder="Enter Your Qualification"
                    isInvalid={errors?.ContactNumber}
                  />
                )}
              />
              {errors.ContactNumber && (
                <span className="error">{errors.ContactNumber.message}</span>
              )}
            </Form.Group>
          </div>
        </div>
        <div className="form-item-container">
          <div className="form-item">
            <Form.Group className="mb-3">
              <Form.Label>Visa Type</Form.Label>
              <Controller
                name="VisaType"
                control={control}
                render={({ field }) => (
                  <select
                    {...field}
                    type="text"
                    id="VisaType"
                    className="form-select"
                    isInvalid={errors?.ContactNumber}
                  >
                    <option value="" selected="selected" hidden>
                      Select Visa Type
                    </option>
                    <option value="Permanent Resident Visa">
                      Permanent Resident Visa
                    </option>
                    <option value="Study Visa">Study Visa </option>
                    <option value="Visit Visa">Visit Visa</option>
                    <option value="Work Visa">Work Visa </option>
                  </select>
                )}
              />
              {errors.ContactNumber && (
                <span className="error">{errors.ContactNumber.message}</span>
              )}
            </Form.Group>
          </div>
          <div className="form-item">
            <Form.Group className="mb-3">
              <Form.Label>Country Interested In</Form.Label>
              <Controller
                name="CountryIntersted"
                control={control}
                render={({ field }) => (
                  <select
                    {...field}
                    type="text"
                    id="CountryIntersted"
                    className="form-select"
                    isInvalid={errors?.ContactNumber}
                    aria-label="Default select example"
                  >
                    <option value="" selected hidden>
                      Select Country
                    </option>
                    <option value="Australia">Australia</option>
                    <option value="Canada">Canada</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States of America">
                      United States of America
                    </option>
                    <option value="Ireland">Dubai</option>
                    <option value="Singapore">New Zealand</option>
                    <option value="Malaysia">Malaysia</option>
                  </select>
                )}
              />
              {errors.ContactNumber && (
                <span className="error">{errors.ContactNumber.message}</span>
              )}
            </Form.Group>
          </div>
        </div>
        <Form.Group className="txt-area">
          {/* <Form.Label>How can we help?</Form.Label> */}
          <Controller
            name="Message"
            control={control}
            render={({ field }) => (
              <Form.Control
                as="textarea"
                rows={5}
                {...field}
                type="text"
                id="Message"
                className="formcontrol"
                placeholder="Tell us a little about your query..."
              />
            )}
          />
        </Form.Group>
        <button
          className="form-button main-btn"
          type="submit"
          variant="primary"
        >
          Book Your Appointment
        </button>
      </Form>
    </section>
  );
};
