import "./App.css";
import { Routes, Route, Navigate } from "react-router";
import { Contact } from "./views/Contact";
import { AboutUs } from "./views/AboutUs";
import { Home } from "./views/Home";
import { Header } from "./components/Header";
import Footer from "./components/Footer";
import { OurTeam } from "./views/OurTeam";
import { Services } from "./views/Services";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Austrial } from "./views/Austraila";
import { UnitedKingdon } from "./views/UnitedKingdom";
import { Ireland } from "./views/Ireland";
import { Germany } from "./views/Germany";
import { Singapore } from "./views/Singapore";
import { Malaysia } from "./views/Malaysia";
import { Canada } from "./views/Canada";
import { Newzland } from "./views/Newzland";
import { USA } from "./views/USA";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/ourteam" element={<OurTeam />} />
        <Route path="/services" element={<Services />} />
        <Route path="/austrila" element={<Austrial />} />
        <Route path="/canada" element={<Canada />} />
        <Route path="/newzland" element={<Newzland />} />
        <Route path="/unitedkingdom" element={<UnitedKingdon />} />
        <Route path="/ireland" element={<Ireland />} />
        <Route path="/germany" element={<Germany />} />
        <Route path="/singapore" element={<Singapore />} />
        <Route path="/malaysia" element={<Malaysia />} />
        <Route path="/usa" element={<USA />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default App;
