import React, { useEffect } from "react";
import { Carsoul } from "../../components/Carsoul";
import { Introduction } from "../../components/Introduction";
import { Services } from "../../components/Services";
import { Callback } from "../../components/Callback";
import "./Home.css";
import { Testimonial } from "../../components/Testimonials";

export const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="home-section">
      <div className="carsoul-section">
        <Carsoul />
      </div>
      <Callback />
      <Introduction />
      <Services />
      <Testimonial />
    </section>
  );
};
