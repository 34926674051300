import React, { useEffect } from "react";

export const Malaysia = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="country-section">
      <div className="country-img-container">
        <div className="country-top-title">Welcome to Malaysia</div>
        <p className="country-quote">
          Experience the Beauty and Diversity of Asia!
        </p>
      </div>
      <div className="countries-text-container">
        <div>
          <h3 className="country-subheading">About Malaysia:</h3>
          <p>
            Malaysia, located in Southeast Asia, is a captivating country known
            for its diverse culture, stunning landscapes, and warm hospitality.
            With a mix of modern cities, ancient rainforests, beautiful islands,
            and a rich heritage, Malaysia offers a unique blend of tradition and
            progress. Whether you&#39;re exploring the bustling streets of Kuala
            Lumpur, diving into the crystal-clear waters of Langkawi, or
            immersing yourself in the vibrant cultural festivals, Malaysia
            promises an unforgettable experience.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose Malaysia?</div>
        </div>
        <div>
          <h3 className="country-subheading">Cultural Melting Pot:</h3>
          <p>
            Malaysia is a multicultural society where people from various ethnic
            backgrounds coexist harmoniously. Immerse yourself in a rich
            tapestry of Malay, Chinese, Indian, and indigenous cultures. Indulge
            in the diverse cuisines, witness traditional performances, and
            celebrate colourful festivals throughout the year.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Natural Wonders:</h3>
          <p>
            Malaysia is blessed with breathtaking natural beauty. Explore
            ancient rainforests teeming with wildlife, climb majestic mountains
            like Mount Kinabalu, or unwind on pristine white- sand beaches. From
            the iconic Petronas Twin Towers to the UNESCO-listed sites of George
            Town and Melaka, Malaysia offers a feast for the senses.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Economic Opportunities:</h3>
          <p>
            Malaysia boasts a robust economy and is a regional hub for business
            and trade. The country provides numerous opportunities for
            investment, entrepreneurship, and career growth. With its strategic
            location and business-friendly policies, Malaysia attracts
            multinational companies and start-ups alike.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Educational Excellence:</h3>
          <p>
            Malaysia is home to reputable universities and colleges offering a
            wide range of academic programs. Whether you&#39;re pursuing
            undergraduate, postgraduate, or vocational studies, Malaysia
            provides a quality education system that combines academic
            excellence with affordability.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Quality of Life:</h3>
          <p>
            Malaysia offers a high standard of living at a relatively lower cost
            compared to many other countries. Enjoy a comfortable lifestyle,
            modern infrastructure, excellent healthcare facilities, and a
            diverse range of leisure activities. The warm climate, friendly
            locals, and safety make Malaysia an ideal place to live, work, and
            raise a family.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Our Services</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Education and Study:</h3>
          <p>
            We specialize in assisting students in pursuing their educational
            goals in Malaysia. Our services cover university and program
            selection, application processing, visa assistance, and guidance
            throughout your academic journey. We understand the unique
            requirements of the Malaysian education system, ensuring a smooth
            transition to your desired institution.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Work and Employment:</h3>
          <p>
            If you&#39;re seeking career opportunities in Malaysia, our services
            can help you navigate the job market and immigration pathways. We
            provide guidance on work permits, job search strategies, and
            professional networking opportunities to enhance your chances of
            securing employment in Malaysia&#39;s diverse industries.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Business and Investment:</h3>
          <p>
            Malaysia offers attractive opportunities for entrepreneurs and
            investors. Our services include business setup, investment guidance,
            and entrepreneur visas. We assist with company incorporation, legal
            compliance, and navigating the business landscape, enabling you to
            establish and grow your business in Malaysia.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Tourism and Visit:</h3>
          <p>
            Discover the wonders of Malaysia through our tourism and visit
            services. Whether you&#39;re planning a short vacation or a longer
            stay, we can help with travel itineraries, visa processing, and
            accommodation arrangements. Experience the vibrant cities, explore
            natural attractions, and immerse yourself in the rich cultural
            heritage of Malaysia.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">
            Malaysia My Second Home (MM2H) Program:
          </h3>
          <p>
            We provide support for the Malaysia My Second Home (MM2H) program,
            which offers long-term residence options for foreigners. Our
            services cover the application process, documentation, and
            assistance in meeting the program requirements, allowing you to
            enjoy the benefits of living in Malaysia.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose Our Services?</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Immigration Expertise:</h3>
          <p>
            Our experienced immigration consultants possess in-depth knowledge
            of the Malaysian immigration system. We stay updated with the latest
            regulations and policies to provide accurate and reliable advice
            tailored to your specific needs.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Personalized Approach:</h3>
          <p>
            We understand that each client has unique goals and circumstances.
            Our services are tailored to address your individual aspirations,
            ensuring that your immigration journey is smooth, efficient, and
            successful.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Comprehensive Support:</h3>
          <p>
            From the initial consultation to post-arrival assistance, we provide
            comprehensive support throughout the entire immigration process. We
            guide you through paperwork, documentation, and application
            submissions, while keeping you informed at every step.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Client Satisfaction:</h3>
          <p>
            Your satisfaction is our top priority. We are committed to providing
            exceptional service and have a track record of successful outcomes.
            Our testimonials and client reviews reflect our dedication to
            excellence and client-centric approach.
          </p>
          <br></br>
          <p>
            <i>
              Embark on a remarkable journey to Malaysia with our comprehensive
              immigration services. Contact us today to explore the beauty,
              diversity, and endless opportunities that await you in this
              enchanting country.
            </i>
          </p>
        </div>
      </div>
    </div>
  );
};
