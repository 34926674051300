import React, { useEffect, useState } from "react";
import "./Ourteam.css";
import teamImg from "../../assests/images/profile.png";
import BasicModal from "../../components/Modal";

export const OurTeam = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClose = () => setOpen(false);

  const btnClickHandler = (name) => {
    setName(name);
    setOpen(true);
  };
  const txtArr = {
    satyapal:
      "Satyapal is a visionary leader with over 2 decades of experience in the field of immigration and overseas education. He is the founder of Study International Group, a parent company of SAS Experts LLP, in the year 2015 after a brief period of working with some of the reputed organizations. He realized there is need to guide students and professionals in the right path with transparent approach and providing quality of service. Satya brings a wealth of knowledge and experience to our team of experts who believe nothing less than success for their clients. Their strategic thinking and forward looking approach have propelled our organization to new heights, allowing us to stay at the forefront of innovation and success.",
    vinay:
      "Vinay is more than immigration specialist who comes with a hands-on experience in the field of immigration. Vinay keeps himself thoroughly informed and his immense knowledge in immigration is used for advantage of clients who are overwhelmed with the information they gather and use for their benefit. Vinay gives his best while guidance, evaluation, and process. Vinay possess a deep understanding of immigration laws, regulations, and procedures, allowing them to provide accurate and personalized guidance to clients. Whether you're seeking Study visas, Visit visas, temporary work permits, permanent residency, or citizenship and navigate the complexities of the immigration system on your behalf.",
    naresh:
      "Naresh comes with a vast experience of 2 decades in the field of marketing and worked with some reputed multi-national organizations and is an expert in the field of strategy in building business. His expertise is an added advantage for our organization as he  explores and brings in new tie-ups which are of great support to students and professionals who aspire to migrate. These vendors are legally authorized agents for governments like IRCC (Immigration, Refugees and Citizenship Canada) agents for Canada, MARA (Migration Agents Registration Authority) agents for Australia, and their expertise in applying increases the chance of approval.",
  };
  return (
    <section className="about-section">
      <div className="about-image-container">Our Team</div>
      <div className="team-section">
        <h1 className="heading ourteamheading">Our Core Team</h1>
        <div className="card-section-container">
          <div className="card-section">
            <div className="team-profile-container">
              <img className="team-profile-img" src={teamImg} alt="card-img" />
            </div>
            <div className="text-container">
              <div className="card-name">Satyapal</div>
              <div className="card-designation">Director</div>
            </div>
            <div className="team-text ">
              Satyapal is a visionary leader with over 2 decades of experience
              in the field of immigration and overseas education. He is the
              founder of Study International Group, a parent company of SAS
              Experts LLP, in the year 2015 after a brief period of working with
              some of the reputed organizations. He realized there is need to
              guide students and professionals in the right path with
              transparent approach and providing quality of service. Satya
              brings a wealth of knowledge and experience to our team of experts
              who believe nothing less than success for their clients. Their
              strategic thinking and forward looking approach have propelled our
              organization to new heights, allowing us to stay at the forefront
              of innovation and success.
            </div>
            <div className="team-btn-container">
              <button
                className="team-btn"
                onClick={() => btnClickHandler("satyapal (Director)")}
              >
                Read More
              </button>
            </div>
            {/* <div className="card-footer">
              <img src={fbImg} alt="social-icon" className="social-img" />
              <img src={instaImg} alt="social-icon" className="social-img" />
              <img src={linkedinImg} alt="social-icon" className="social-img" />
            </div> */}
          </div>
          <div className="card-section">
            <div className="team-profile-container">
              <img className="team-profile-img" src={teamImg} alt="card-img" />
            </div>
            <div className="text-container">
              <div className="card-name">Naresh</div>
              <div className="card-designation">Marketing Manager</div>
            </div>
            <div className="team-text ">
              Naresh comes with a vast experience of 2 decades in the field of
              marketing and worked with some reputed multi-national
              organizations and is an expert in the field of strategy in
              building business. His expertise is an added advantage for our
              organization as he explores and brings in new tie-ups which are of
              great support to students and professionals who aspire to migrate.
              These vendors are legally authorized agents for governments like
              IRCC (Immigration, Refugees and Citizenship Canada) agents for
              Canada, MARA (Migration Agents Registration Authority) agents for
              Australia, and their expertise in applying increases the chance of
              approval.
            </div>
            <div className="team-btn-container">
              <button
                className="team-btn"
                onClick={() => btnClickHandler("naresh (Marketing Manager)")}
              >
                Read More
              </button>
            </div>
            {/* <div className="card-footer">
              <img src={fbImg} alt="social-icon" className="social-img" />
              <img src={instaImg} alt="social-icon" className="social-img" />
              <img src={linkedinImg} alt="social-icon" className="social-img" />
            </div> */}
          </div>
          <div className="card-section">
            <div className="team-profile-container">
              <img className="team-profile-img" src={teamImg} alt="card-img" />
            </div>
            <div className="text-container">
              <div className="card-name">Vinay</div>
              <div className="card-designation">Immigration Specialist</div>
            </div>
            <div className="team-text ">
              Vinay is more than immigration specialist who comes with a
              hands-on experience in the field of immigration. Vinay keeps
              himself thoroughly informed and his immense knowledge in
              immigration is used for advantage of clients who are overwhelmed
              with the information they gather and use for their benefit. Vinay
              gives his best while guidance, evaluation, and process. Vinay
              possess a deep understanding of immigration laws, regulations, and
              procedures, allowing them to provide accurate and personalized
              guidance to clients. Whether you're seeking Study visas, Visit
              visas, temporary work permits, permanent residency, or citizenship
              and navigate the complexities of the immigration system on your
              behalf.
            </div>
            <div className="team-btn-container">
              <button
                className="team-btn"
                onClick={() =>
                  btnClickHandler("vinay (Immigration Specialist)")
                }
              >
                Read More
              </button>
            </div>
            {/* <div className="card-footer">
              <img src={fbImg} alt="social-icon" className="social-img" />
              <img src={instaImg} alt="social-icon" className="social-img" />
              <img src={linkedinImg} alt="social-icon" className="social-img" />
            </div> */}
          </div>
        </div>
      </div>
      <BasicModal
        title={name}
        body={txtArr[name && name.split(" ")[0]]}
        open={open}
        handleClose={handleClose}
      />
    </section>
  );
};
