import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./Modal.css";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ title, body, open, handleClose }) {
  console.log(body);
  return (
    <div className="modal-section">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: { xs: "90%", sm: 500, md: 500, lg: 500 },
            height: 500,
            overflowY: "scroll",
            padding: "10px 30px",
            position: "relative",
          }}
        >
          <div className="close-btn-container">
            <CloseIcon onClick={handleClose} className="close-icon" />
          </div>
          <div>
            <h6 className="title">{title}</h6>
          </div>
          <div>
            <p className="body">{body}</p>
          </div>
          <div className="cls-btn-container">
            <button onClick={handleClose} className="cls-btn">
              Close
            </button>
          </div>
          {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {body}
          </Typography> */}
        </Box>
      </Modal>
    </div>
  );
}
