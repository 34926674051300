import React, { useEffect } from "react";

export const Singapore = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="country-section">
      <div className="country-img-container">
        <div className="country-top-title">Welcome to Singapore</div>
        <p className="country-quote">
          The Lion City, Where Tradition Meets Innovation!
        </p>
      </div>
      <div className="countries-text-container">
        <div>
          <h3 className="country-subheading">About Singapore:</h3>
          <p>
            Singapore, a thriving city-state in Southeast Asia, is a unique
            blend of tradition, multiculturalism, and technological advancement.
            Known for its modern skyline, lush greenery, and clean streets,
            Singapore offers a vibrant and cosmopolitan environment. With its
            strong economy, excellent infrastructure, and world-class education
            and healthcare systems, Singapore is an attractive destination for
            individuals seeking opportunities for education, career advancement,
            and an exceptional quality of life.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose Singapore?</div>
        </div>
        <div>
          <h3 className="country-subheading">Economic Powerhouse:</h3>
          <p>
            Singapore is one of the world&#39;s leading financial and business
            hubs. Its strategic location, favourable business environment, and
            robust economy make it an ideal destination for entrepreneurs,
            professionals, and multinational companies. Benefit from its pro-
            business policies, low tax rates, and extensive network of
            international connections.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Global Education Hub:</h3>
          <p>
            Singapore is renowned for its top-quality education system. The
            country hosts prestigious universities and institutions that offer a
            wide range of programs across various disciplines. With a focus on
            innovation, research, and industry relevance, Singapore provides an
            excellent platform for students to excel and thrive.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Cultural Diversity:</h3>
          <p>
            Singapore is a melting pot of cultures, with a rich blend of
            Chinese, Malay, Indian, and other ethnic influences. Experience a
            diverse range of cuisines, festivals, and traditions that reflect
            the multicultural fabric of the nation. Singapore&#39;s commitment
            to inclusivity and harmony creates a welcoming and harmonious
            environment for residents and visitors alike.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Technological Advancement:</h3>
          <p>
            Singapore is at the forefront of technological innovation and
            digital transformation. The city-state embraces smart city
            initiatives, sustainable practices, and cutting- edge technologies.
            Explore a thriving ecosystem of startups, research centers, and tech
            companies, making Singapore an exciting hub for innovation and
            entrepreneurship.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">High Quality of Life:</h3>
          <p>
            Singapore consistently ranks high in global quality of life indexes.
            Enjoy a clean and safe environment, efficient public transportation,
            world-class healthcare facilities, and a high standard of living.
            Singapore&#39;s commitment to green spaces and sustainable
            development creates a harmonious balance between urban and natural
            landscapes.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Our Services</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Study and Education:</h3>
          <p>
            We specialize in providing comprehensive support to students
            aspiring to study in Singapore. From university and course selection
            to application processing, visa assistance, and accommodation
            arrangements, we ensure a seamless transition to your chosen
            educational institution.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Work and Employment:</h3>
          <p>
            Whether you&#39;re seeking career opportunities or professional
            advancement in Singapore, our services can guide you through the
            immigration and employment processes. We provide guidance on work
            permits, job search strategies, and networking opportunities to
            enhance your chances of success in Singapore&#39;s competitive job
            market.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Entrepreneurship and Business:</h3>
          <p>
            Singapore offers an ideal environment for entrepreneurs and
            businesses. We provide guidance on company incorporation, business
            setup procedures, and investment opportunities. Our team can assist
            you in navigating the legal and regulatory requirements, allowing
            you to establish and grow your business in Singapore.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Permanent Residency:</h3>
          <p>
            If you&#39;re considering settling in Singapore for the long term,
            we can help you explore the options for permanent residency. Our
            services cover eligibility assessments, documentation preparation,
            and submission of PR applications, ensuring a smooth and streamlined
            process.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">
            Relocation and Settlement Support:
          </h3>
          <p>
            Moving to a new country can be challenging, but our dedicated team
            is here to support you in your relocation and settlement journey. We
            provide assistance with accommodation, orientation, community
            integration, and access to essential services, ensuring a smooth
            transition to your new life in Singapore.
          </p>
        </div>
        <div className="country-subtitle">
          <div className="heading">Why Choose Our Services?</div>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Immigration Expertise:</h3>
          <p>
            Our experienced immigration consultants possess in-depth knowledge
            of the Singaporean immigration system. We stay updated with the
            latest regulations and policies to provide accurate and reliable
            advice tailored to your specific needs.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Personalized Approach:</h3>
          <p>
            We understand that each client has unique goals and circumstances.
            Our services are tailored to address your individual aspirations,
            ensuring that your immigration journey is smooth, efficient, and
            successful.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Comprehensive Support:</h3>
          <p>
            From the initial consultation to post-arrival assistance, we provide
            comprehensive support throughout the entire immigration process. We
            guide you through paperwork, documentation, and application
            submissions, while keeping you informed at every step.
          </p>
        </div>
        <div className="country-sub-container">
          <h3 className="country-subheading">Client Satisfaction:</h3>
          <p>
            Your satisfaction is our top priority. We are committed to providing
            exceptional service and have a track record of successful outcomes.
            Our testimonials and client reviews reflect our dedication to
            excellence and client-centric approach.
          </p>
          <br></br>
          <p>
            <i>
              Embark on a remarkable journey to Singapore with our comprehensive
              immigration services. Contact us today to explore the endless
              opportunities that await you in this dynamic and culturally
              diverse city-state.
            </i>
          </p>
        </div>
      </div>
    </div>
  );
};
